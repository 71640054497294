@import "//netdna.bootstrapcdn.com/font-awesome/3.0/css/font-awesome.css";

.login-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
}
.ms_2
{
  margin-left: 5px;
}

.login-popup-inner {
  background-color: #fff;
  padding: 0px;
  padding-top: 0px;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  max-width: 400px;
  /* height: 350px; */
  width: 100%;
  border-radius: 20px;
}

.login-popup-inner-inner {
  padding: 20px;
  padding-top: 0px;
}

.login-popup-inner h1 {
  margin-top: 0;
  font-size: 30px;
  color: #1a1d29;
}

.login-popup-inner form {
  display: flex;
  flex-direction: column;
  margin-bottom: 0px;
  width: 100%;
}

.login-popup-inner label {
  display: block;
  margin-bottom: 10px;
}

.input-container {
  position: relative;
  display: flex;
  align-items: center;
  background-color: #f7f7f7;
  border-radius: 4px;
  justify-content: space-between;
  padding-right: 15px;
}
.input-container:hover,
.input-container:focus {
  outline: 1px solid black;
}
.input-container button {
  border: none;
  background: inherit;
  cursor: pointer;
  color: #1c1e2c;
  font-weight: 700;
  font-size: 14px;
  outline: none;
}
.input-container input {
  border: none;
  background: inherit;
  outline: none;
}

.login-popup-inner input[type="number"],
.login-popup-inner input[type="email"] {
  padding: 16px 20px;
  padding-left: 50px;
  border: none;
  width: 57%;
  border-radius: 8px;
  font-size: 16px;
}

.login-popup-inner button[type="submit"] {
  margin-top: 30px;
  padding: 20px;
  background-color: #f8b301;
  color: #000;
  font-weight: bold;
  font-size: 15px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
}
.loginBar {
  display: flex;
  justify-content: space-between;
}
.loginBar h1 {
  font-weight: 600;
  font-size: 24px;
  padding: 16px;
  padding-top: 32px;
}
.close-btn {
  background-color: transparent;
  border: none;
  font-size: large;
  cursor: pointer;
}
.cross {
  padding-bottom: 20px;
}
.mobile {
  color: #888888;
  font-size: 20px;
}

.phobile-icon {
  position: absolute;
  top: 50%;
  left: 15px;
  transform: translateY(-50%);
  color: #888888;
  font-size: 20px;
  font-weight: bold;
}
.divider {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 20px 0;
}

.line {
  width: 35%;
  height: 1px;
  background-color: #ccc;
}
.text {
  padding: 0 10px;
  font-weight: bold;
  color: #888888;
  font-size: 13px;
}
.mobile-icon {
  margin-left: 1px;
}

.tab-group {
  list-style: none;
  padding: 0;
  margin: 0 0 40px 0;
  display: flex;
  background-color: rgba(30, 33, 48, 1);
  border-radius: 20px 20px 0px 0px;
}
.tab-group:after {
  content: "";
  display: table;
  clear: both;
}
.tab-group li a {
  display: block;
  text-decoration: none;
  padding: 15px 0px;
  background: rgba(30, 33, 48, 1);
  color: #a0b3b0;
  font-size: 12px;
  float: left;
  width: 50%;
  text-align: center;
  transition: 0.5s ease;
}
.tab-group .active a {
  background: rgba(255, 255, 255, 1);

  color: rgba(30, 33, 48, 1);
}
.tab-content > div:last-child {
  display: none;
}

.tab {
  width: 50%;
  cursor: pointer;
}

.tab button {
  /* display: flex; */
  /* justify-content: center; */
  /* align-items: center; */
  border-radius: 0px 20px 0px 0px;
  padding: 2.5rem;
  background-color: rgba(30, 33, 48, 1);
  width: 100%;
  color: white;
  outline: none;
  border: none;
  border-bottom: 1px solid #ddd;
}

.tab-group .active button {
  border-radius: 20px 4px 0px 0px;
  background-color: rgba(255, 255, 255, 1);
  color: rgba(30, 33, 48, 1);
}
@media (max-width: 400px) {
  .login-popup-inner {
    max-width: 95vw;
  }
}

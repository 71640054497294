.container {
    padding: 20px 50px;
}

.bread_crumb {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    padding: 30px 0px;
    margin: 20px 0px;
}

.bread_crumb .home_icon {
    color: #757575;
    font-size: 22px;
}

.bread_crumb .arrow_icon {
    font-size: 18px;
    color: #D0D5DD;
}

.bread_crumb span {
    background-color: #FDEBB0;
    border-radius: 6px;
    padding: 6px 15px;
    font-size: 14px;
    font-weight: 600;
    font-family: Mulish;
    color: #1E2130;
}

.section0 {
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.section0 .slide_section {
    width: 65%;
}

.section0 .sec0rightdiv {
    width: 35%;
}

.sec0rightdiv .rightdivel1 {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.three_section {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.three_section .main_sec {
    display: flex;
    flex-direction: column;
    width: 65%;
}

.bottom_boxes {
    border: 1px solid #DFDFDF;
    border-radius: 16px;
    padding: 40px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 27%;
    height: max-content;
}

.product_main {
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.product_main .two_sec {
    width: 66%;
}

.finance_box {
    font-size: 16px;
    font-weight: 400;
    font-family: Mulish;
    color: #404041;
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
}

.finance_box .image {
    width: 24px;
    height: 24px;
}

.section2 {
    margin-top: 5%;
}

.rightdivel1 button {
    background: none;
    border: none;
}

.rightdivel1 h1 {
    font-family: Mulish;
    font-size: 28px;
    font-weight: 800;
    color: #1E2130;
}

.rightdivtable {
    width: 100%;
    border: 1px solid #BDBDBD;
    border-radius: 8px;
}

.divtableData {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #BDBDBD;
}


.tabledatatext {
    font-family: Mulish;
    border-right: 1px solid #BDBDBD;
    margin: 0px;
    font-size: 18px;
    font-weight: 600;
    width: 35%;
    padding: 10px 16px;
}

.divtableData .table_para {
    font-size: 16px;
    font-weight: 600;
    color: #757575;
    font-family: Mulish;
    margin: 0px;
    padding: 10px 16px;
}

.divtableData:last-child {
    border-bottom: none !important;
}

.rightdivel3 {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 20px;
    flex-wrap: wrap;
}

.viewofferbtn {
    padding: 15px 40px;
    border: none;
    background: #F4BA00;
    border-radius: 5px;
    font-size: 14px;
    color: #fff;
    font-weight: 700;
    font-family: Mulish;
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    justify-content: center;
}

.dealerbtn {
    padding: 15px 40px;
    border: 1.5px solid #1A1A1A;
    background: none;
    border-radius: 5px;
    font-size: 14px;
    color: #1A1A1A;
    font-weight: 700;
    font-family: Mulish;
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
}

.pricetext {
    font-size: 28px;
    color: #219653;
    font-weight: 800;
    font-family: Mulish;
    margin: 25px 0px !important;
}

.section1 {
    display: flex;
    flex-direction: row;
}

.sliderDiv {
    width: 50%;
    display: flex;
    flex-direction: row;
}

.sec1cardDiv {
    width: 100%;
}

.sec1cardDiv img {
    height: 500px;
    width: 100%;
}


.section2dd {
    border-radius: 8px;
    margin-top: 20px;
}

.dropdown1 {
    height: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    padding: 8px 0px;
}

.dropdown1 p {
    font-size: 20px;
    color: #1a1a1a;
    font-weight: 500;
    margin-left: 20px;
}

.dropdown1 .para_active {
    color: #fff;
}

.dropdown1 .icon_active {
    color: #fff;
    font-size: 20px;
    font-weight: 600;
}

.dropdown1 .icon {
    color: #1a1a1a;
    font-size: 20px;
}


.dropdown1 button {
    background: none;
    border: none;
    height: 30px;
    margin-right: 20px;
}

.detaildown {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}

.detaildown .table {
    display: flex;
    flex-direction: column;
}

.detaildown .table .table_row {
    display: flex;
    flex-direction: row;
}

.detaildown .range_sliders {
    display: flex;
    flex-direction: column;
    gap: 22px;
}

.detaildown .slider1 {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.detaildown .slider1 .heading {
    font-size: 16px;
    font-weight: 500;
    color: #1a1a1a;
    margin: 0;
}

.detaildown .slider1 .slider {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
}

.detaildown .slider1 .slider .price {
    font-size: 15px;
    font-weight: 600;
    color: #404041;
    width: 12%;
}

.input_range {
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    cursor: pointer;
    outline: none;
    overflow: hidden;
    border-radius: 4px;
}

/* Track: webkit browsers */
.input_range::-webkit-slider-runnable-track {
    height: 10px;
    background: #DFDFDF;
    border-radius: 4px;
}

.input_range::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    height: 10px;
    width: 10px;
    background-color: #fff;
    border-radius: 50%;
    border: 2px solid #F4BA00;
    box-shadow: -407px 0 0 400px #F4BA00;
}


.tabledata1:last-child {
    border-bottom: none !important;
}

.emiRange {
    width: 100%;
    padding: 0 20px;
}

.emiRange p {
    color: #4F4F4F;
    font-size: 17px;
    font-family: Mulish;
}


.inputsliderandtext {
    display: flex;
    flex-direction: row;

}

.inputsliderandtext p {
    font-size: 16px;
    font-family: Mulish;
    font-weight: 800;
    margin-left: 5px;
}

.inputslider {
    width: 70%;
    color: black;
}

.tabledata1 {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #BDBDBD;
    padding: 0px 20px;
}

.tabledata1 .tablepara {
    font-size: 16px;
    font-weight: 500;
    color: #1a1a1a;
    margin: 0px;
    padding: 16px 20px;
    border-right: 1px solid #bdbdbd;
    width: 15%;
}

.tabledata1 p {
    font-size: 16px;
    font-weight: 500;
    margin: 0px;
    padding: 16px 20px;
    color: #757575;
}

.Emidetaildiv {
    margin-top: 40px;
    border: 1px solid #E0E0E0;
    border-radius: 8px;

}

.emidetailrow {
    display: flex;
    flex-direction: row;
    padding: 20px;
    border-bottom: 1px solid #E0E0E0;
}

.emidetailrow .detail_head {
    font-size: 18px;
    font-weight: 500;
    color: #1a1a1a;
    margin: 0px;
    width: 30%;
}

.emidetailrow .detail_price {
    font-size: 19px;
    font-weight: 600;
    color: #F4BA00;
    margin: 0px;
}

.emidetailrow .detail_price1 {
    font-size: 19px;
    font-weight: 600;
    color: #219653;
    margin: 0px;
}

.emidetailrow:last-child {
    border-bottom: none !important;
}

/* .emidetailrow p {
    font-size: 18px;
    font-weight: 500;
    color: #1a1a1a;
    width: 30%;
    margin-left: 2%;
    font-family: Mulish;
} */

.section3 {
    padding: 20px 0;
    border: 1px solid #F4BA00;
    border-radius: 8px;
    margin-top: 20px;
}

.section3innerdiv {
    padding: 0 15px;
}

.section3innerdiv h1 {
    font-size: 24px;
    font-weight: 700;
    color: #1a1a1a;
    margin: 0px;
    margin-top: 10px;
    font-family: Mulish;
}

.section3innerdiv .mapdiv {
    /* height: 200px; */
    width: 100%;
    border: 1px solid #BDBDBD;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
}

.section3innerdiv .mapdiv span {
    color: #F1F1F1;
}

.section3innerdiv .mapdiv p {
    font-size: 35px;
    padding: 0;
    color: #757575;
}

.section3innerdiv button {
    width: 100%;
    background-color: #F4BA00;
    border: none;
    margin-top: 20px;
    padding: 18px 0px;
    border-radius: 5px;
    color: white;
    font-size: 16px;
    font-weight: 500;
}

.section4 {
    margin-top: 5%;
    margin-bottom: 5%;
}

.section4 .titlediv {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 20px;

}

.section4 .titlediv p {
    font-size: 24px;
    font-weight: 700;
    font-family: Mulish;
    color: #1a1a1a;
    margin: 0px;
}

.section4 .titlediv button {
    border: none;
    background: none;
    font-size: 16px;
    font-weight: 500;
    color: #2F80ED;
}

.explore-truck-section {
    margin-top: 40px !important;
    padding: 0px !important;
    min-height: unset !important;
}

.dataDiv {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1%;
}

.dataCard {
    border: 1px solid #E0E0E0;
    border-radius: 8px;
    padding: 10px 15px;
    margin-bottom: 10px;
}

.dataCard .main_content {
    padding: 10px;
}

.dataCard:hover {
    border: 1px solid #F4BA00;
}

.dataCard img {
    height: 330px;
    width: 100%;
    object-fit: cover;
    border-radius: 8px;
}

.dataCard h2 {
    color: #1A1A1A;
    font-weight: 500;
    font-size: 20px;
    width: 100%;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    margin: 0px;
    margin-top: 20px;
    font-family: Mulish;
}

.dataCard p {
    color: #219653;
    font-size: 18px;
    font-weight: 700;
    margin: 0px;
    margin-top: 20px;
    font-family: Mulish;
}

.dataCard .cattextdiv {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.cattextdiv .textdivofcat {
    height: 30px;
    background-color: #FFF5D6;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    padding: 4px 18px;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    color: #F4BA00;
}

.section5 {
    border: 1px solid #E0E0E0;
    border-radius: 8px;
    margin-top: 5%;
}

.section5 .main_heading {
    padding: 20px 40px;
}

.section5 .heading {
    font-size: 24px;
    font-weight: 700;
    color: #1a1a1a;
    margin: 0px;
    font-family: Mulish;
}

.section5 .para {
    font-size: 15px;
    font-weight: 400;
    color: #757575;
    margin: 0px;
    margin-top: 10px;
}

.section5 .video {
    padding: 20px;
    border-radius: 4px;
    position: relative;
}

.section5 .video_img {
    width: 100%;
    height: 550px;
    object-fit: cover;
    border-radius: 4px;
    position: relative;
}

.section5 .play_button {
    position: absolute;
    top: 50%;
    left: 50%;
    right: 0;
    bottom: 0;
    text-align: center;
    width: 55px !important;
    height: 55px !important;
    border-radius: 50%;
    background-color: #000000ba !important;
    border: none !important;
}

.section5 .play_button .icon {
    color: #fff;
    font-size: 32px;
}

.main_modal {
    background-color: #1a1a1a82;
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 9999;
}

.main_modal .modal {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    margin: auto;
    width: 500px;
    height: max-content;
    background-color: #fff;
    border-radius: 8px;
    padding: 50px;
}

.main_modal .fields {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 20px;
}
.main_modal .fields h2
{
    margin-top: 0px!important;

}

.main_modal .input_box {
    padding: 1.5rem 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 15px;
    font-weight: 500;
    color: #1A1A1A;
    font-family: Mulish;
}

.main_modal .btn_submit {
    background-color: #1E2130;
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    font-family: Mulish;
    border-radius: 4px;
    border: 1px solid #1E2130;
    padding: 1.5rem 3rem;
    margin-top: 40px;
}

.main_modal .icon {
    position: absolute;
    top: 2rem;
    right: 3rem;
}

.main_modal .icon svg {
    font-size: 24px;
}

input:focus-visible {
    outline: none;
}

@media only screen and (min-width:1166px) and (max-width:1300px) {
    .dataDiv {

        gap: 0.5%;
    }
}

@media only screen and (min-width:400px) and (max-width:1165px) {
    .dataDiv {
        gap: 1%;
    }

    .dataCard {
        border: 1px solid #E0E0E0;
        border-radius: 8px;
        padding: 10px 15px;
        margin-bottom: 10px;
    }
}

@media only screen and (min-width:400px) and (max-width:1100px) {
    .section0 {
        display: flex;
        flex-direction: column;
        gap: 15px;
    }

    .sec0rightdiv {
        width: 100%;
    }
}

@media only screen and (min-width:350px) and (max-width:700px) {

    .section2dd {
        margin-top: 15px;
    }

    .dropdown1 {
        width: 100%;
    }

    .tabledata1 p {
        font-size: 14px;
        width: 30% !important;
    }

    .section3 {
        width: 100%;
    }

    .section3innerdiv {
        width: 95%;
    }

    .dataCard {
        border: 1px solid #E0E0E0;
        border-radius: 8px;
        padding: 10px 15px;
        margin-bottom: 10px;
    }
}

@media only screen and (min-width:350px) and (max-width:480px) {
    .rightdivel3 {
        flex-direction: column;
    }

    .viewofferbtn {
        width: 100%;
    }

    .dealerbtn {
        width: 100%;
    }
}

/* productdetail page */

@media only screen and (min-width:300px) and (max-width:600px) {

    .container {
        padding: 20px;
    }

    .product_main {
        flex-direction: column-reverse;
    }

    .bottom_boxes {
        width: unset;
        padding: 12px;
    }

    .product_main .two_sec {
        width: 100%;
    }

    .finance_box .image {
        width: 20px;
        height: 20px;
    }

    .finance_box {
        font-size: 14px;
    }

    .section0 {
        flex-direction: column;
    }

    .section0 .slide_section {
        width: 100%;
    }

    .bread_crumb {
        margin: 0px;
        padding: 15px 0px;
    }

    .swiper_slide .big_slide {
        height: 325px !important;
    }

    .mySwiper {
        margin-top: 20px;
    }

    .section0 .sec0rightdiv {
        width: 100%;
    }

    .finance_box {
        width: max-content;
    }

    .section2 {
        width: 100%;
    }

    .section3 {
        width: 100%;
    }

    .section5 .main_heading {
        padding: 20px 15px;
    }

    .section5 .video {
        padding: 10px;
    }

    .section5 .video_img {
        height: 345px;
    }

    .section5 .play_button {
        top: 43%;
        left: 43%;
    }

    .dataCard img {
        height: 285px;
    }

    .main_modal .modal {
        width: 75%;
        padding: 15px;
    }
    .main_modal .fields h2
{
    margin-top: 0px!important;
    margin-bottom: 10px;
}

    .main_modal .fields {
        gap: 12px;
    }
    
    .main_modal .input_box {
        padding: 1rem 1rem;
        font-size: 14px;
    }

    .main_modal .icon {
        top: 8%;
        right: 1rem;
    }

    .main_modal .btn_submit
    {
        font-size: 14px;
        padding: 1rem 3rem;
        margin-top: 15px;
    }

    .main_modal .fields {
        margin-top: 10px;
    }

    .rightdivel1 h1 {
        font-size: 24px;
    }

    .pricetext {
        font-size: 24px;
    }
}


@media only screen and (min-width:400px) and (max-width:600px) {
    .main_modal .modal {
        width: 300px;

    }
}

@media only screen and (min-width:500px) and (max-width:600px) {
    .main_modal .modal {
        width: 350px;

    }
}


/* @media only screen and (min-width:1170px) and (max-width:1170px) {
    .container {
        padding: 20px;
    }

    .section0 {
        flex-direction: column;
    }

    .section0 .slide_section {
        width: 100%;
    }

    .swiper_slide .big_slide {
        height: 580px;
    }

    .section0 .sec0rightdiv {
        width: 100%;
    }

    .section2 {
        width: 100%;
    }

    .section3 {
        width: 100%;
    }

    .bread_crumb {
        margin: 0px;
    }

    .dataCard {
        padding: 10px 12px;
    }

    .dataCard img {
        height: 270px;
    }

    .finance_box {
        width: max-content;
    }
}

@media only screen and (min-width:1290px) and (max-width:1290px) {
    .container {
        padding: 20px;
    }

    .section0 {
        flex-direction: column;
    }

    .section0 .slide_section {
        width: 100%;
    }

    .swiper_slide .big_slide {
        height: 640px;
    }

    .section0 .sec0rightdiv {
        width: 100%;
    }

    .section2 {
        width: 100%;
    }

    .section3 {
        width: 100%;
    }

    .bread_crumb {
        margin: 0px;
    }

    .dataCard {
        padding: 10px 12px;
    }

    .dataCard img {
        height: 270px;
    }

    .finance_box {
        width: max-content;
    }
}

@media only screen and (min-width:1179px) and (max-width:1179px) {
    .container {
        padding: 20px;
    }

    .section0 {
        flex-direction: column;
    }

    .section0 .slide_section {
        width: 100%;
    }

    .swiper_slide .big_slide {
        height: 640px;
    }

    .section0 .sec0rightdiv {
        width: 100%;
    }

    .section2 {
        width: 100%;
    }

    .section3 {
        width: 100%;
    }

    .bread_crumb {
        margin: 0px;
    }

    .dataCard {
        padding: 10px 12px;
    }

    .dataCard img {
        height: 270px;
    }

    .finance_box {
        width: max-content;
    }
}

@media only screen and (min-width:1284px) and (max-width:1284px) {
    .container {
        padding: 20px;
    }

    .section0 {
        flex-direction: column;
    }

    .section0 .slide_section {
        width: 100%;
    }

    .swiper_slide .big_slide {
        height: 640px;
    }

    .section0 .sec0rightdiv {
        width: 100%;
    }

    .section2 {
        width: 100%;
    }

    .section3 {
        width: 100%;
    }

    .bread_crumb {
        margin: 0px;
    }

    .dataCard {
        padding: 10px 12px;
    }

    .dataCard img {
        height: 270px;
    }

    .finance_box {
        width: max-content;
    }
} */


/* emi claculator of product detil page */
.carddContainer {
    border: 1px solid #E0E0E0;
    border-radius: 8px;
    margin-top: 30px;
}



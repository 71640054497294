.vehicle-option-btn-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
}

.image-upload-row {
  display: flex;
  flex-direction: row;
  gap: 5px;
  padding: 5px;
}

.image-preview-wrapper {
  position: relative;
}

.cancel-icon {
  position: absolute;
  top: 5px;
  right: 5px;
  cursor: pointer;
}

.imageUploadConatiner {
  display: flex;
  flex-direction: column;
  padding: 5px;
}
.image-upload-item .upload-icon-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  padding: 25px;
}

.image-upload-item .upload-icon-wrapper svg {
  width: 50px; /* Adjust the width and height to match your cloud icon */
  height: 50px;
}

.image-upload-item .upload-icon-wrapper span {
  display: block;
  text-align: center;
}

.image-upload-item {
  width: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 39px, 22px, 39px, 22px;
  border: 2px solid #e0e0e0;
  border-style: dashed;
  border-radius: 20px;
}

.uploaded-image {
  max-width: 100%; /* Ensure the image does not exceed the width of its container */
  max-height: 100%; /* Ensure the image does not exceed the height of its container */
  object-fit: cover; /* Ensure the image covers the entire container */
}

.vehicle-option-btn {
  padding: 12px 42px !important;
  display: flex;
  align-items: center;
}

.vehicle-option-btn > span {
  font-size: 16px;
  font-weight: 400;
  color: #1e2130;
  margin-left: 10px;
}

.vehicle-option-btn-container > .enabled-btn {
  padding: 12px 42px;
  background-color: #ffe6a5;
  border-radius: 7px;
  color: #1e2130;
  font-weight: 700;
  cursor: pointer;
  margin: 10px;
}

.vehicle-option-btn-container > .disabled-btn {
  padding: 12px 42px;
  background-color: #e0e0e0;
  border-radius: 7px;
  color: #1e2130;
  opacity: 0.5;
  font-weight: 700;
  cursor: pointer;
}

.vehicle-detail-fields-box {
  display: grid;
  grid-template-areas:
    "make model"
    "purchasedYear bodyType"
    "fuelList payloadType"
    "cargoList tyreList"
    "price totalKmDriven" /* Add a row for totalKmDriven */
    "RcNumber wight";

  gap: 20px;

  grid-template-columns: repeat(2, 1fr); /* Equal width for both columns */
}

.totalKm-grid-item {
  grid-area: totalKmDriven; /* Define grid area for totalKmDriven */
}

.rc-grid-item {
  grid-area: RcNumber; /* Define grid area for totalKmDriven */
}

.make-grid-item {
  grid-area: make;
}

.model-grid-item {
  grid-area: model;
}

.purchasedYear-grid-item {
  grid-area: purchasedYear;
}

.price-grid-item {
  grid-area: price;
}
.bodyType-grid-item {
  grid-area: bodyType;
}
.fuelList-grid-item {
  grid-area: fuelList;
}
.payload-item {
  grid-area: payloadType;
}
.cargoList-grid-item {
  grid-area: cargoList;
}
.tyreList-grid-item {
  grid-area: tyreList;
}
.wight-grid-item {
  grid-area: wight;
}

.additional-req-box {
  width: 100%;
}

.additional-req-box > p {
  color: #757575;
  font-size: 14px;
  font-weight: 700;
  margin-top: 30px;
}

.additional-req-box > textarea {
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  width: 100%;
  padding: 15px;
  outline-style: none;
}

/* Keep the existing styles */

/* For mobile view */
/* For mobile view */
@media screen and (max-width: 768px) {
  .vehicle-detail-fields-box {
    display: flex;
    flex-direction: column;
    align-items: revert;
    justify-content: center;
  }

  .vehicle-option-btn-container > .disabled-btn {
    padding: 12px 42px;
    background-color: #e0e0e0;
    border-radius: 7px;
    color: #1e2130;
    opacity: 0.5;
    font-weight: 700;
    cursor: pointer;
    width: 150px;
    justify-content: center;
  }
  .vehicle-option-btn-container > .enabled-btn {
    padding: 12px 42px;
    background-color: #ffe6a5;
    border-radius: 7px;
    color: #1e2130;
    font-weight: 700;
    cursor: pointer;
    width: 150px;
    justify-content: center;
  }
  .vehicle-option-btn-container {
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
  }
  .field-icon-container select option {
    font-size: 14px; /* Increase font size for better readability */
    padding: 10px; /* Adjust padding for better spacing */
  }

  .vehicle-option-btn {
    margin-bottom: 10px;
  }

  .field-icon-container select {
    width: 100%; /* Set the width to 100% for mobile */
  }

  .field-icon-container select option {
    font-size: 9px;
  }

  .image-upload-row {
    flex-wrap: wrap; /* Allow images to wrap to the next line */
    gap: 10px; /* Increase gap between images */
    justify-content: center; /* Center images */
  }

  .image-upload-item {
    flex-basis: calc(
      50% - 10px
    ); /* Set width for each image to take half the container width with gap */
  }

  .image-upload-item .upload-icon-wrapper {
    padding: 15px; /* Adjust padding for better spacing */
  }

  .image-upload-item .upload-icon-wrapper svg {
    width: 30px; /* Adjust cloud icon size */
    height: 30px;
  }

  .image-upload-item .upload-icon-wrapper span {
    font-size: 12px; /* Adjust text size */
  }
}

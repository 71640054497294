.personal-detail-fields-box {
    display: grid;
    grid-template-areas: 'name' 'phone' 'mail' 'state' 'district' 'tehsil';
    gap: 20px;
}

@media (min-width: 768px) {
    .personal-detail-fields-box {
        grid-template-areas: 'name phone' 'mail state' 'district tehsil';
    }
}

.field-icon-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 15px;
    border: 1px solid #E0E0E0;
    border-radius: 8px;
}

.field-icon-container>span {
    font-size: 16px;
    font-weight: 600;
    margin-left: 10px;
}

.field-icon-container>.input-field-icon {
    width: 20px;
    height: 20px;
}

.field-icon-container>input,
.field-icon-container>select {
    border: none;
    font-size: 16px;
    width: 100%;
    margin-left: 10px;
    padding: 2px;
    background-color: none;
    outline-style: none;
}

.field-icon-container>input::placeholder,
.field-icon-container>select::placeholder {
    /* color: #888888; */
}

.field-icon-container>select>option {
    padding: 10px;
}

.name-grid-item {
    grid-area: name;
}

.phone-grid-item {
    grid-area: phone
}

.mail-grid-item {
    grid-area: mail;
}

.state-grid-item {
    grid-area: state;
}

.district-grid-item {
    grid-area: district;
}

.tehsil-grid-item {
    grid-area: tehsil;
}
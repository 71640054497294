

.multi-range-slider {
 /* border: "none", padding: "10px 15px", boxShadow: "none",width:"100%",gridArea:"range" }} */

 border:none;
 padding :10px 20px 10px 15px;
 box-shadow: none;
 grid-area: range;
}

.multi-range-slider  > .bar {
    height: 2px;
    
}

.multi-range-slider > .bar > .bar-left {
    height: 2px;
    padding:0px
}



.multi-range-slider .thumb::before {
    content: '';
    background-color: inherit;
    position: absolute;
    width: 20px;
    height: 20px;
    border: solid 1px black;
    box-shadow: none;
    border-radius: 50%;
    z-index: 1;
    margin: -10px -12px;
    cursor: pointer;
}


.multi-range-slider .label {
    font-size: 80%;
    display: flex;
    width: 1px;
    justify-content: center;
}

.multi-range-slider .label:last-child {
    justify-content: flex-start;
    white-space: nowrap;
    margin-right:70px;
    margin-top:5px;
    color:#757575;
    font-size: 14px;
}

.multi-range-slider .label:first-child {
    justify-content: flex-start;
    white-space: nowrap;
    margin-top:5px;
    color:#757575;
    font-size: 14px;

}


.multi-range-slider .thumb .caption {
  bottom :0px;
  top:35px

}

.multi-range-slider .thumb .caption * {
    position: absolute;
    min-width: 30px;
    font-size:14px;
    text-align: center;
    background-color:#F1F1F1;
    border-radius: 7px;
    border:1px solid #E0E0E0;
    color: #757575;
    box-shadow: 2px 2px 30px #0000001A;
    white-space: nowrap;
}

.finance-detail-fields-box {
    display: grid;
    grid-template-areas: 'range range'
    'finance time'
    ;
    gap:20px
}


.finance-grid-item {
    grid-area: finance;
}

.range-grid-item {
    grid-area: range;
}

.time-grid-item {
    grid-area: time;
}


.auction-div {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 50%;
    justify-content: space-between;
    margin-top:15px
}

.auction-div > p {
    font-size: 18px;
    font-weight: 600;
    color:#1E2130CC
}

.auction-div > div {
    display: flex;;
    flex-direction: row;
    align-items: center;
    padding: 10px;
    border: 1px solid #E0E0E0;
    border-radius: 8px;

}

.auction-div > div > select {
    background-color: transparent;
    
}

/* For screens smaller than 600px (typical phone screens) */
@media only screen and (max-width: 600px) {
    .finance-detail-fields-box {
        grid-template-areas: 'range' 'finance' 'time';
    }

    .finance-grid-item,
    .range-grid-item,
    .time-grid-item {
        width: 100%; /* Make each input take up 100% width */
    }
    .auction-div {
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        justify-content: space-between;
        margin-top: 15px;
    }
    .multi-range-slider .label:last-child {
        justify-content: flex-start;
        white-space: nowrap;
        margin-right: 45px;
        margin-top: 5px;
        color: #757575;
        font-size: 14px;
    }
    
}

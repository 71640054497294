.vehicle-option-btn-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
}

.vehicle-option-btn {
  padding: 12px 42px !important;
  display: flex;
  align-items: center;
}

.vehicle-option-btn > span {
  font-size: 16px;
  font-weight: 400;
  color: #1e2130;
  margin-left: 10px;
}

.vehicle-option-btn-container > .enabled-btn {
  padding: 12px 42px;
  background-color: #ffe6a5;
  border-radius: 7px;
  color: #1e2130;
  font-weight: 700;
  cursor: pointer;
  margin: 10px;
}

.vehicle-option-btn-container > .disabled-btn {
  padding: 12px 42px;
  background-color: #e0e0e0;
  border-radius: 7px;
  color: #1e2130;
  opacity: 0.5;
  font-weight: 700;
  cursor: pointer;
}

.vehicle-detail-fields-box {
  display: grid;
  grid-template-areas:
    "make model"
    "bodyType lengthType"
    "capacityType manufactureType";
  gap: 20px;
}

.make-grid-item {
  grid-area: make;
}

.model-grid-item {
  grid-area: model;
}

.body-grid-item {
  grid-area: bodyType;
}

.length-grid-item {
  grid-area: lengthType;
}

.capacity-grid-item {
  grid-area: capacityType;
}

.manufacture-grid-item {
  grid-area: manufactureType;
}

.additional-req-box {
  width: 100%;
}

.additional-req-box > p {
  color: #757575;
  font-size: 14px;
  font-weight: 700;
  margin-top: 30px;
}

.additional-req-box > textarea {
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  width: 100%;
  padding: 15px;
  outline-style: none;
}

/* Keep the existing styles */

/* For mobile view */
@media screen and (max-width: 768px) {
  /* Adjust the layout of vehicle-option-btn-container */
  .vehicle-option-btn-container {
    flex-direction: column; /* Change flex direction to column */
    align-items: center; /* Align items to the start */
    margin-bottom: 10px; /* Reduce margin for better spacing */
    justify-content: center;
  }

  /* Adjust vehicle-option-btn */
  .vehicle-option-btn {
    margin-bottom: 10px; /* Add margin to create space between buttons */
    width: 100px;
  }

  /* Adjust vehicle-detail-fields-box */
  .vehicle-detail-fields-box {
    display: grid;
    grid-template-areas: "make" "model" "body" "length" "capacity" "manufacture"; /* Display each input in its own row */
    gap: 10px; /* Reduce gap between items */
  }
  .field-icon-container select {
    width: 100%; /* Make the select element full width */
    padding: 10px; /* Add padding for better touch interaction */
    /* Add margin to separate each select element */
  }

  .field-icon-container select option {
    font-size: 8px;
  }
  .field-icon-container > select > option {
    padding: 0px;
    margin: 0px;
  }
}

/* header */
.navbar {
    display: grid;
    grid-template-columns: 1fr 0fr;
    justify-content: space-between;
    padding: 2rem 5rem;
    height: 5.5rem;
    position: relative;
    border-bottom: 2px solid rgb(232, 232, 232);
    background-color: white;
}

.navbar .btn-register{
    width: 18rem;
    height: 5.5rem;
    border-radius: 0.8rem;
    font-family: "Mulish";
    font-style: normal;
    font-weight: 600;
    font-size: 1.5rem;
    color: rgb(255, 255, 255);
    background-color: rgb(5, 5, 5);
    padding: 0px 10px;
}

.navbar .btn-register:hover {
    background-color: rgb(70, 70, 70);
    transform: scale(1.1);
    transition: all 0.1s ease-in-out 0s;
}

.bottom_boxes1 {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
    padding: 20px 40px 40px 40px;
}
.finance_box1 {
    flex: 1;
    font-size: 16px;
    font-weight: 400;
    font-family: Mulish;
    color: #404041;
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
    text-align: center;
}

.finance_box1 .image {
    width: 50px;
    height: 50px;
}

@media only screen and (min-width:300px) and (max-width:600px) {

    .bottom_boxes1 {
        width: unset;
        padding: 12px;
    }

    .finance_box1 .image {
        width: 20px;
        height: 20px;
    }

    .finance_box1 {
        font-size: 14px;
    }


    .finance_box1 {
        width: max-content;
    }


    .navbar{
        padding: 2rem 1rem;
    }

    .navbar .btn-register{
        padding: 0px 10px;
        width: 12rem;
    }
   
}
.wrapper {
    width: 90%;
    margin: 0px auto;
    padding-bottom: 30px;
  }

  .blog-basic-info > h1 {
 font-size: 51px;

  }


  .date-tag-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 30px;
  }


  .date-tag-container > .date-part {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: nowrap;
  }

  .date-tag-container > .tag-part {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: nowrap;
    margin-left: 10px;
  }

  .blog-data-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    margin-top: 50px;
  }

  .blog-content {
  flex:3.2;
  flex-shrink: 0;
  }


  .blog-content > img {
    width:100%;
    aspect-ratio: 9/6 auto;
    border-radius: 5px;
    overflow: hidden;
    height:550px;
    
  }
.blog-content > .blog-post-discription {

  font-size: 20px;
  text-align: justify;
  /* line-height: 1.8rem ; */
}

.blog-content > .blog-post-discription > p  {

  /* line-height: 1.8rem ; */

}

.post-content {
    font-size: 20px;  
    text-align: justify;
}

.post-content > ol > li > p {
   font-size: 18px;
   text-align: justify;
}

  .left-section {
    flex:1.6;
    flex-shrink: 0;
  }


  .left-section > div {
    padding-left: 30px
  };


  .navigation-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content:space-between;
    width: 100%;
    margin:60px 0
  }


  .prev-navigate {
    margin-right: auto;
      border:1px solid #E0E0E0;
      padding:12px 16px;
      border-radius: 8px;
      display: flex ;
    align-items: center;
    justify-content: center;
    text-decoration: none;
  }

  .prev-arrow-circle {
    background-color: #F4BA00;
    margin-right: 8px;
    height:34px;
    width:34px;
    border-radius: 100%;
    display: flex ;
    align-items: center;
    justify-content: center;
    margin-right:5px;
    color:white

  }


  .next-navigate {
    margin-left: auto;
    border:1px solid #E0E0E0;
    padding:12px 16px;
    border-radius: 8px;
    display: flex ;
    flex-direction: row-reverse;
    text-decoration: none;
  align-items: center;
  justify-content: center;
}

.next-arrow-circle {
  transform: rotate(180deg);
  background-color: #F4BA00;
  height:34px;
  margin-left: 8px;
  width:34px;
  border-radius: 100%;
  display: flex ;
  align-items: center;
  justify-content: center;
  margin-right:5px;
  color:white

}


.share-component {
  display: flex;
  margin:0px auto;
  border:1px solid #E0E0E0;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  border-radius: 7px;
}



.share-component > span {
  font-size: 18px;
  font-weight: 700;
  white-space: nowrap;
}

.share-component > div {
  flex:1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}


.financed-section {
  background-color: #1E2130;
  width: full;
  padding: 20px;
  border-radius:7px ;
  margin-top: 30px;
}

.financed-section > h3 {
  font-size: 16px;
  color: #FDEBB0;
  font-weight: 700;
}

.financed-section > p {
   font-size: 28px;
   font-weight: 700;
   color: white;
}


.financed-section > .icon-div {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin:20px auto;
}

.financed-section > .btn-alignment{ 
    display: flex;
    flex-direction: row;
    margin-top: 30px;
}



.cavalo-blog-section {
  padding: 20px; 
  border:1px solid #D4D4D4;
  border-radius: 7px;
  margin-top: 30px;
}


.cavalo-blog-section > .section-1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.cavalo-blog-section > .section-1 > h3 {
  font-size: 16px;
  color: #1E2130BF;
  font-weight: 700;
}

.cavalo-blog-section > .section-1 > div {
  padding: 10px;
  background-color:#F4BA00;
  border-radius: 5px;
  color:#1E2130
}


.cavalo-blog-section > p {
  font-size: 28px;
  font-weight: 700;
  color: #1E2130;
}

.cavalo-blog-know-more {
  padding: 10px;
  background-color: #1E2130;
  color:white;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  text-decoration: none;
}



  .navigation-text > p {
    font-size: 16px;
    font-weight:400;
    color: #4F5261;;
    margin:0px

  }

  .navigation-text > h3 {
    font-size: 24px;
    font-weight: 700;
    color:#181A2A;
    margin:0px
  }

  .related-article-blogs-section > h3 {
  font-size: 40px;
  
  }
  

  .related-article-blogs-section > p {
    font-size: 25px;
  }

  .related-articles-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap:30px
  }





  @media screen and (max-width:425px) {

    .blog-content > img {
      height:auto;
      
    }

    .blog-basic-info > h1 {
      font-size: 25px;
     
       }
       .date-tag-container {
        flex-direction: column;
        align-items: flex-start;
      }
    
      .blog-data-container {
        flex-direction: column;
        margin-top: 50px;
      }
      .date-tag-container > .tag-part {
        margin-top: 10px;
        flex-wrap: wrap;
        margin-left: 0px;
      }
    
      .blog-content > .blog-post-discription {
        font-size: 18px;
      }
  
  
  .post-content {
    font-size: 18px;  
  }

  .left-section {
    flex:1; 

    flex-shrink: 0;
  }

  .left-section > div {
    padding: 0px
  };

  .share-component {
    padding:10px
  }

  .share-component > span {
    font-size: 16px;
  }


  .financed-section {
    padding: 10px;
  }


  .navigation-container {
    margin:50px auto;
  }

  .prev-navigate {
      padding:6px 8px;
  }

  .prev-arrow-circle {
    height:20px;
    width:20px;
    margin-right:2px;
    flex-shrink: 0;
  }


  .next-navigate {
    padding:6px 8px;
 
}

 .next-arrow-circle {
  margin-left: 2px;
  height:20px;
  width:20px;
  flex-shrink: 0;
 }

.navigation-text > h3 {
  display: none;
}


.related-articles-container {
  display: grid;
  grid-template-columns: 1fr;
  gap:0px
}

.related-article-blogs-section > h3 {
  font-size: 25px;
  
  }
  

  .related-article-blogs-section > p {
    font-size: 18px;
  }

  }


  @media screen and (min-width:2150px) {
    .blog-content > img {
      height:700px;
      
    }

    .blog-content > .blog-post-discription {
      font-size: 26px;
    }


.post-content {
  font-size: 26px;  
}
  }
.login-popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99;
  }
  
  .register-popup-inner {
    position: absolute;
    top: 80px;
    background-color: #fff;
    padding: 20px;
    border-radius: 4px;
    /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3); */
    max-width: 400px !important;
    /* width: 100%; */
    border-radius: 30px;
    padding-top:0;

  }
  
  .register-popup-inner h1 {
    margin-top: 0;
    font-size: 30px;
  }
  
  .register-popup-inner form {
    display: flex;
    flex-direction: column;
    margin-bottom: 0px;
    width: 100%;
  }
  
  .register-popup-inner label {
    margin-bottom: 10px;
  }
  
  .register-popup-inner input[type="text"],
  .register-popup-inner input[type="password"] {
    padding: 20px;
    border: none;
    background-color: #f7f7f7;
    border-radius: 4px;
    width: 65%;
    padding-left: 40px;
  }
  .register-popup-inner input[type="number"]{
    padding: 20px;
    border: none;
    background-color: #f7f7f7;
    border-radius: 4px;
    width: 65%;
    padding-left: 40px;
  }
  .register-popup-inner input[type="email"]{
    width: 100%;
    padding: 20px;
    border: none;
    background-color: #f7f7f7;
    border-radius: 4px;
    /* box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1); */
    padding-left: 40px;
  }
  .register-popup-inner input[type="tel"]{
    width: 100%;
    padding: 20px;
    border: none;
    background-color: #f7f7f7;
    border-radius: 4px;
    /* box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1); */
    padding-left: 40px;
    /* margin-right: 10px; */
  }
  input::placeholder{
    color: #888888;
  }
  .register-popup-inner select{
    padding: 20px;
    border: none;
    background-color: #f7f7f7;
    border-radius: 4px;
    /* box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1); */
    width: 96%;
    color: #888888;
    padding-left: 40px;

  }
  .register-popup-inner button[type="submit"] {
    /* margin-top: 30px; */
    padding: 20px;
    background-color: #f8b301;
    color: #000;
    font-weight: bold;
    font-size: 15px;
    border: none;
    border-radius: 10px;
    cursor: pointer;
    
  }
  .mobile-phone {
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
  }
  .registerBar {
    display: flex;
    justify-content: space-between;
  }
  .close-btn {
    background-color: transparent;
    border: none;
    font-size: large;
    outline:none;
  
  }
  .cross{
    padding-bottom: 20px;
  }

  .input-container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 15px;
    border-radius: 4px;
  }
  .mobile-icon{
      position: absolute;
      top: 50%;
      left: 15px;
      transform: translateY(-50%);
      color:#888888;
      font-size: 20px;
      font-weight: bold;
  }
  .registerInputContain{
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 1rem;
    
  }
  .tab-group {
  list-style: none;
  padding: 0;
  margin: 0 0 40px 0;
  display: flex;
  background-color: rgba(30, 33, 48, 1);
  border-radius: 20px 20px 0px 0px;

}
.tab-group:after {
  content: "";
  display: table;
  clear: both;
}
.tab-group li a {
  display: block;
  text-decoration: none;
  padding: 15px 0px;
  background: rgba(30, 33, 48, 1);
  color: #a0b3b0;
  font-size: 12px;
  float: left;
  width: 50%;
  text-align: center;
  transition: 0.5s ease;
}
.tab-group .active a {
  background: rgba(255, 255, 255, 1);

  color: rgba(30, 33, 48, 1);

}
.tab-content > div:last-child {
  display: none;
}

.tab1{
  width:50%;
  cursor: pointer;
}

.tab1 button {
  /* display: flex; */
  /* justify-content: center; */
  /* align-items: center; */
  border-radius: 20px 4px 0px 0px;
  padding: 2.5rem;
  background-color: rgba(30, 33, 48, 1);
  width: 100%;
  color: white;
  outline: none;
  border: none;
  border-bottom: 1px solid #ddd;

}
select{
  outline: none;
  border: none;
}


.tab1.active button{
  border-radius: 0px 20px 0px 0px;
  background-color: rgba(255, 255, 255, 1);
  color:rgba(30, 33, 48, 1);
}
.container-inner {
    background-color: #fff;
    /* padding: 20px; */
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
    max-width: 400px;
    width: 100%;
    border-radius: 30px;
    position: relative;
    height: 550px

  }

  @media (max-width: 400px){
    .container-inner {
      max-width: 95vw
    }
  }
  /*  */


.card-detail-container {
    border : 1px solid #E0E0E0;
    border-radius: 8px;
    padding: 16px;
    width:fit-content

}

.card-detail-container:hover {
    transform: scale(1.05)
}

@media screen and (max-width:1024px) {
    .card-detail-container:hover {
        transform:none
    }
    
}

.card-image-container {
    /* height:240px; */
    aspect-ratio: 9/6 auto;
    margin-bottom: 12px;
    /* object-fit: cover; */
}


.card-tag-container {
 display: flex;
 flex-direction: row;
 align-items: center;
 flex-wrap: wrap;
}


.card-tag {
    padding: 4px 12px 4px 12px; 
    border-radius: 3px;
    background-color: #F7F7F7;
    font-weight: 500;
    font-size: 12px;
    color: #616161;
    white-space: nowrap;
    margin-bottom: 5px;
    margin-right: 2px;

}

.card-heading {
    font-size :24px;
    font-weight: 700px;
    color: #181A2A;
    margin-top:5px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;

}

.card-text {
    color: #4F5261;
    font-weight: 400;
    font-size: 16px;
    margin-top:5px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 5;
    overflow: hidden;

}

.date-text {
    color:#929292;
    font-size:12px;
    font-weight:400
}


.blog-link {
    text-decoration: none;
}

/* .blog-link :hover {
   text-decoration: underline;
} */


@media screen and (max-width: 1024px) {
   
   .card-tag {
       font-size: 10px;
       margin-right: 1px;
   
   }
   
   .card-heading {
       font-size :18px;
   }
   
   .card-text {
       font-size: 14px;
   }

   
}

@media screen and (min-width:2150px) {
    .card-text {
        font-size:20px
    }
  }

/* 
@media screen and (max-width : 768px) {

    .card-image-container {
    height:180px;
}


.card-heading {
    font-size :18px;
}

.card-text {
    font-size: 14px;
    margin-top:2px
}

.date-text {
    font-size:12px;
}
} */

/* 

@media screen and (min-width : 1024px) {

    .card-image-container {
    height:200px;
}




.card-tag {
    font-size: 10px;
}

.card-heading {
    font-size :20px;
}

.card-text {
    font-size: 14px;
    margin-top:2px
}

.date-text {
    font-size:12px;
}
} */
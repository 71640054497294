.wrapper {
  width: 90%;
  margin: 0px auto;
  padding-bottom: 30px;
}

.blog_heading
{
  text-align: center;
  margin: 30px 0px 0px 0px;
  font-size: 30px;
  font-family: "Mulish";
  font-weight: 800;
  line-height: 120%;
  letter-spacing: -0.21px;
}


.blog-section1 {
  width: 100%;
  height: 500px;
  position: relative;
  margin-bottom: 170px;
}

.static-card-content {
  position: absolute;
  bottom:-100px;
  padding: 32px;
  width:500px;
  left:90px;
  background-color: white;
  border:1px solid #E8E8EA;
  box-shadow:0px 18px 32px rgba(24, 26, 42, 0.2);
  border-radius: 8px;
}

.static-card-content > h2 {
  font-size: 34px;
  color: #1E2130;
  font-weight: 700;
}

.static-card-content > p {
  color:#4F5261;
  font-weight: 400;
  font-size: 16px;
}

.tag-container {
  display: flex;
  flex-direction: row;
  /* align-items: center; */
}

.sub-heading {
  font-size: 28px;
  color: #181a2a;
  font-weight: 700;
}

.tag-heading {
  color: #555866;
  font-size: 18px;
  white-space: nowrap;
}

.sub-tag-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.non-highlighted-tag {
  padding: 6px 14px 6px 14px;
  border-radius: 4px;
  background-color: #f7f7f7;
  font-weight: 500;
  font-size: 14px;
  color: #616161;
  margin-left: 10px;
  white-space: nowrap;
  margin-top: 5px;
  cursor: pointer;
}

.highlighted-tag {
  padding: 6px 14px 6px 14px;
  border-radius: 4px;
  background-color: #f8b301;
  font-weight: 500;
  margin-left: 10px;
  font-size: 14px;
  color: #1e2130;
  white-space: nowrap;
  margin-top: 5px;
  cursor: pointer;
}

.blog-card-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  width: 100%;
  margin: 3rem 0;
  padding-top: 1rem;
  padding-left: 0.5rem;
}

.pagination-container {
  width: fit-content;
  margin-left: auto;
  margin-top: 70px;
}

.pagination-container > ul {
  list-style: none;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.pagination-container > ul > li {
  list-style: none;
  display: flex;
  font-size: 18px;
  color: #1e2130;
  margin: 0px 7px;
  cursor: pointer;
  flex-direction: row;
  user-select: none;
}

.pagination-container > ul > li > a[aria-disabled="true"] {
  opacity: 0.5;
}

.activeClassName {
  font-weight: 600;
  border: 1px solid #1e2130;
  padding: 0px 5px;
  border-radius: 3px;
}

.section-2 {
  background-color: #1e2130;
  padding: 42px 48px;
  margin-top: 30px;
  border-radius: 7px;
}

.section-2 > h3 {
  color: #fdebb0;
  font-weight: 700;
  font-size: 16px;
}

.section-2 > div {
    position: relative;
}


.section-2 > div > p {
  font-size: 30px;
  font-weight: 700;
  color: white;
}

.btn-container {
 height:fit-content;
position: absolute; 
right: 0;
bottom: 0;
display: flex;
flex-direction: row;
}

.know-more {
  background-color: #f8b301;
  color: #1e2130;
  padding: 12px 22px;
  border:1px solid #f8b301;
  font-size: 16px;
  border-radius:4px;
  margin-right:8px;
  cursor: pointer;
  font-weight: 600;
}

.contact-us {
    color: #1e2130;
    cursor: pointer;
    padding: 12px 22px;
    font-size: 16px;
    border:1px solid #ffffff;
    border-radius:4px;
    display: flex;
    align-items: center;

}

.contact-us svg{
  color: #1e2130;
  stroke: #1e2130 !important;
  fill: #1e2130 !important;
}

.contact-us > span {
    margin-left: 3px;
    font-weight: 600;
}


.margin_3
{
   margin: 3rem auto;
 
}

.w_90
{
  width: 90%!important;
  margin: 0 auto;
}


@media screen and (max-width:1024px) {

  .blog-section1 {
    height:400px
  }

.pagination-container > ul > li {
  font-size: 14px;
}

.section-2 {
  background-color: #1e2130;
  padding: 42px 48px;
  margin-top: 30px;
  border-radius: 7px;
}



.section-2 > div > p {
  font-size: 24px;
}


.know-more {
  background-color: #f8b301;
  color: #1e2130;
  padding: 10px 20px;
  border:1px solid #f8b301;
  font-size: 14px;
  border-radius:4px;
  margin-right:8px;
  cursor: pointer;
}

.contact-us {
    color: #1e2130;
    cursor: pointer;
    padding: 10px 20px;
    font-size: 14px;
    border:1px solid #ffffff;
    border-radius:4px;
    display: flex;
    align-items: center;
}

.contact-us > span {
    margin-left: 3px;
}

  
}

@media screen and (max-width:768px) {
  .blog_heading
  {
      margin: 20px 0px 0px 0px;
    font-size: 22px;
    font-weight: 700;
  }
  .margin_3
  {
     margin: 1rem auto;

  }
  .blog-section1 {
    height:350px
  }


  .static-card-content {
    bottom:-100px;
    padding:24px 32px;
    width:500px;
    left:50%;
    transform: translateX(-50%);
  }


.section-2 {
  padding: 34px 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
}


.section-2 > div {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.section-2 > div > p {
  font-size: 22px;
  font-weight: 700;
  color: white;
}

.btn-container {
position:static; 
}

.know-more {
  background-color: #f8b301;
  color: #1e2130;
  padding: 12px 22px;
  border:1px solid #f8b301;
  font-size: 16px;
  border-radius:4px;
  margin-right:8px;
  cursor: pointer;
}

.contact-us {
    color: #1e2130;
    cursor: pointer;
    padding: 12px 22px;
    font-size: 16px;
    border:1px solid #ffffff;
    border-radius:4px;
    display: flex;
    align-items: center;
}

.contact-us > span {
    margin-left: 3px;
    font-weight: 600;
}

}

@media screen and (max-width:426px) {

  .blog-section1 {
    height: 250px;
    margin-bottom: 130px;
  }
  
  .static-card-content {
    position: absolute;
    bottom:-100px;
    padding: 16px;
    width:250px;
    left:50%;
    transform: translateX(-50%);
  }
  
  .static-card-content > h2 {
    font-size: 26px;
    margin:2px
  }
  
  .static-card-content > p {
    font-size: 14px;
  }
  .tag-container {
    flex-direction: column;
    /* align-items: center; */
  }
  
  .sub-tag-container {
    display: flex;
    flex-direction: row;
    margin-top:10px;
    flex-wrap: wrap;
  }
  
  .non-highlighted-tag {
    padding: 5px 12px;
    margin-right:2px;
  }
  
  .highlighted-tag {
    padding: 5px 12px;
    margin-right:2px;
  }
  
  .blog-card-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
  }
  
  .pagination-container {
    width: 100%;
    margin-top: 70px;
  }
  
  .pagination-container > ul {
    list-style: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  
  .pagination-container > ul > li {
    list-style: none;
    display: flex;
    font-size: 14px;
    color: #1e2130;
    margin: 0px;
    cursor: pointer;
    flex-direction: row;
    user-select: none;
  }
  
  

  .section-2 {
    padding: 30px 36px;
  }
  
 
  
  .section-2 > div > p {
    font-size: 22px;
  
    text-align: center;
  }
  
  .btn-container {
  position: static; 
  
  }
  
  .know-more {
    background-color: #f8b301;
    color: #1e2130;
    padding: 6px 10px;
    border: 1px solid #f8b301;
    font-size: 14px;
    font-weight: 600;
  }
  
  .contact-us {
      padding: 12px 22px;
      font-size: 14px;
      font-weight: 500;
  }
  
 

}



@media screen and (min-width: 1700px) {
  .blog-card-container {
    grid-template-columns: repeat(4, 1fr);
  }
}

@media screen and (min-width: 2560px) {
  .blog-card-container {
    grid-template-columns: repeat(5, 1fr);
  }

  .section-2 {
    padding: 42px 80px;
  }

/* 
  .static-card-content {
    padding: 28px;
    width:600px;
  } */

}




.submit-section {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90%;
    margin: 0px auto;
}


.submit-section > h2 {
   font-weight: 800;
   text-align: center;
   font-size: 36px;
}

.submit-section > p {
   font-size:20px;
   text-align: center;
   
   font-weight: 400;

}
@import "//netdna.bootstrapcdn.com/font-awesome/3.0/css/font-awesome.css";

.login-popup-1 {
  position: absolute;
  width: 20%;
  max-width: 250px;

  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);

  display: flex;
  justify-content: center;
  align-items: center;
  margin: 3rem;
  z-index: 99;
  right: 3vw;
}
.login-popup-inner-1 {
  border-radius: 12px;
  background: #fff;
  box-shadow: 0px 6px 60px 0px rgba(21, 22, 31, 0.08);
  box-sizing: border-box;
  height: 460;
  width: 284px;
  display: flex;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
}
.person {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  gap: 12px;
  overflow: hidden;
}

.person h3 {
  color: #15161f;
  font-size: 16px;
  font-family: Mulish;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.person h4 {
  color: #5e636f;
  font-size: 12px;
  font-family: Mulish;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.person div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
}
.person img {
  width: 44px;
  height: 44px;
}

.person-1 {
  display: flex;
  width: 244px;
  height: 44px;
  padding: 0px 18px 0px 12px;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
  border-radius: 8px;
  border: 1.2px solid #ebebeb;
  background: #fafafa;
  box-sizing: border-box;
}

.person-1 img {
  width: 18px;
  height: 18px;
  flex-shrink: 0;
}

.person-1 h4 {
  color: #15161f;
  font-size: 14px;
  font-family: Mulish;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin: 0;
  }

.buy-seller {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  padding: 16px 0 16px;
  border-top: 1px solid #ebebeb;
  border-bottom: 1px solid #ebebeb;
}
.buy-seller button {
  display: flex;
  width: 244px;
  padding: 12px 16px;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
  border-radius: 8px;
  background-color: #fff;
  margin: 0;
}
.buy-seller button:hover {
  background-color: #d7d7d7;
}

.buy-seller img {
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  margin: 0;
}

.buy-seller p {
  color: #5e636f;
  font-size: 14px;
  font-family: Mulish;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0;
}

.submit {
  display: flex;
  width: 244px;
  padding: 12px 16px 12px 0px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  box-sizing: border-box;
  background: #f8b300;
}
.submit svg {
  width: 20px;
  margin: 0;
  height: 20px;
}

.submit p {
  color: #15161f;
  font-size: 14px;
  font-family: Mulish;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0;
}

@media (max-width: 850px) {
  .login-popup-1 {
    min-width: 78%;
    position: absolute;
    top: -3rem;
  }
  .login-popup-inner-1{
    width:100%
  }
  .person{
    width:100%
  }
  .person-1{
    width:100%
  }.buy-seller{
    width:100%
  }.submit{
    width:100%
  }
}

.headingmain{
    font-size: 3.0rem;
    margin-bottom: 0;
}
.pgrcontact{
    font-size: 1.6rem;
    color: #616161;
}
.customeline{
    margin: 2rem 0;
    border-bottom: 1px solid #d3d3d3;
}
.perheading{
    font-size: 1.6rem;
    color: #4b4e5a;
    font-family: Mulish;
}
.position-relative{
    position: relative;
}
.input-field-icon{
    position: absolute;
    top: 16px;
    left: 9px;
}
.cusomtinput{
    padding: 1.5rem 1rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 15px;
    font-weight: 500;
    color: #838383;
    font-family: Mulish;
}
.w100{
    width: 97.5%;
}

.grid_column{
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    justify-content: space-between;
}
.input_group{
    width: 48%;
    margin-bottom: 15px;
}
.mb-4{
    margin-bottom: 20px;
}
.mt-5{
    margin-top: 5rem;
}
.confromfont{
    font-size: 1.6rem;
    font-family: Mulish;
}
.modecss{
    font-size: 1.5rem;
    margin: 0;
    font-family: Mulish;
}
.model_list{
    display: flex;
    gap: 15px;
    margin: 5px 0;
}
.radibtuton label{
    font-size: 1.5rem;
    font-family: Mulish;
}
.w100textrea{
    width: 100%;
    display: block;
    padding: 15px;
    border-color: #e0e0e0;
    border-radius: 5px;
    font-family: Mulish;
}
textarea:focus { 
    outline: none !important;
    /* border-color: #719ECE; */
    /* box-shadow: 0 0 10px #719ECE; */
}
.submitbutton{
    width: 100%;
    display: block;
    padding: 1.4rem 1rem;
    /* height: 45px; */
    cursor: pointer;
    background-color: #f4ba01;
    border-radius: 10px;
    border-color: #f4ba01;
    margin: 1.5rem 0;
    box-shadow: none;
    border: none;
    font-size: 2.4rem;
    font-family: Mulish;

}
.bottombarfooter{
    background-color: #1e2130;
    padding: 30px;
    margin: 3rem 0;
    border-radius: 10px;
    display: flex;
    flex-wrap: wrap;
    align-items: end;
}
.col-8{
    width: 80%;
    flex: 1;
}
.col-4{
    width: 20%;
    flex: 1;
    display: flex;
    justify-content: center;
}
.bottombarfooter h5{
    font-size: 2.1rem;
    color:#fdebb0;
    text-transform: uppercase;
    margin-bottom: 1rem;
}
.bottombarfooter h2{
    font-size: 3.5rem;
    color:#fff;
    margin-bottom: 1rem;
    line-height: 40px;
}
.bottombarfooter span{
    color: #f8b301;
}
.buttonare .knowmore{
    display: inline-block;
    padding: 1rem 1.6rem;
    color: #1e2130;
    background-color: #f8b301;
    font-family: Mulish;
    font-size: 1.6rem;
    border-radius:4px;
    margin-right: 2.3rem;
    text-decoration: none;
    font-weight: 700;
    border: 2px solid #f8b301;
}
.buttonare .contactUs{
    display: inline-block;
    padding: 1rem 1.6rem;
    color: #fff;
    border: 2px solid #fafafa;
    font-family: Mulish;
    font-size: 1.6rem;
    border-radius:4px;
    margin-right: 10px;
    text-decoration: none;
    font-weight: 700;
}
@media(max-width:992px){
    .col-8{
        min-width: 100%;
        margin-bottom: 2rem;
    }
}
@media(max-width:786px){
    .input_group {
        width: 100%;
        margin-bottom: 15px;
    }
    .w100 {
        width: 98%;
    }
    .cusomtinput{
        padding-left: 3px;
        width: 98%;
    }
    .w100textrea {
        width: 90%;
    }
    .bottombarfooter {
        padding: 20px 15px;
    }
    .bottombarfooter h2{
        font-size: 2rem;
        line-height: unset;
    }
    .vehicle-req-container.mobilewidt95{
        padding: 10px 19px;
        width: 92%;
    }
}
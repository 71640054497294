


.terms-condition-heading-style {
    font-size:28px;
    font-weight: 700;
}

.term-condition-info-style {
    font-size: 18px;
    text-align: justify;
    margin-top:20px;
}


.terms-of-use-style {
    padding:10px;
    width:100%;
    border-left: 4px solid #006db7;
    background-color: #f6f6f6;
    font-size: 18px;
    font-weight: 700;
    margin-top: 40px;
    margin-bottom: 20px;

}

.terms-of-use-content {

margin:50px 3px

}

.terms-of-use-content > h3 {
    font-size: 18px;
    font-weight: 700;
}

.terms-of-use-content > p {

     font-size: 17px;
     font-weight: 400;
     text-align: justify;

}

.terms-condition-footer-info-style {
    font-size: 18px;
    text-align: justify;
    margin-top:20px;
}
.calculator .container {
    padding: 20px 50px;
}

.bread_crumb {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    padding: 30px 0px;
    margin: 20px 0px;
}

.bread_crumb .home_icon {
    color: #757575;
    font-size: 22px;
}

.bread_crumb .arrow_icon {
    font-size: 18px;
    color: #D0D5DD;
}

.bread_crumb span {
    background-color: #FDEBB0;
    border-radius: 6px;
    padding: 6px 15px;
    font-size: 14px;
    font-weight: 600;
    font-family: Mulish;
    color: #1E2130;
}

.calculator .main_content {
    padding: 0px 10%;
}

.calculator .main_content .heading {
    font-size: 32px;
    font-weight: 800;
    font-family: Mulish;
    margin: 0px;
    color: #1E2130;
}

.calculator .main_content .image_box {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.image {
    width: 350px;
    height: 350px;
}

.pie_box {
    border: 1px solid #F4BA00;
    border-radius: 10px;
    overflow: hidden;
}

.pie_box h6 {
    font-size: 24px;
    font-weight: 600;
    color: #fff;
    font-family: Mulish;
    background-color: #F4BA00;
    margin: 0px;
    padding: 20px;
}

.pie_chart {
    display: flex;
    flex-direction: row;
    gap: 14%;
    padding: 40px;
}

.pie_chart .chart {
    width: 250px;
    height: 250px;
}

.right_side {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.right_side .top_sec {
    display: flex;
    flex-direction: row;
    gap: 12%;
}

.right_side .tags {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
}

.right_side .circle {
    width: 20px;
    height: 20px;
    border-radius: 50%;
}

.right_side .tags span {
    font-size: 16px;
    font-weight: 400;
    color: #757575;
    font-family: Mulish;
}

.right_side .inputs {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 25px;
}

.right_side .inputs .input {
    border: 1px solid #E0E0E0;
    border-radius: 8px;
    padding: 18px;
    font-size: 14px;
    font-weight: 400;
    color: #757575;
    font-family: Mulish;
}

.main_content .bottom_sec {
    border: 1px solid #E0E0E0;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    margin: 40px;
}

.bottom_sec .first {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 20px;
    border-bottom: 1px solid #E0E0E0;
}

.bottom_sec .first p {
    font-size: 20px;
    font-weight: 500;
    font-family: Mulish;
    color: #1A1A1A;
    margin: 0;
}

.bottom_sec .first .first_span {
    font-size: 24px;
    font-weight: 700;
    font-family: Mulish;
    color: #2F80ED;
}

.bottom_sec .first .second_span {
    font-size: 24px;
    font-weight: 700;
    font-family: Mulish;
    color: #F4BA00;
}

.bottom_sec .second {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 20px;
}

.bottom_sec .second .main {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.second .main .head {
    font-size: 24px;
    font-weight: 700;
    font-family: Mulish;
    color: #F4BA00;
}

.second .main .text {
    font-size: 20px;
    font-weight: 500;
    font-family: Mulish;
    color: #757575;
    padding: 0px;
}

.bottom_sec .second .main2 {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 15px;
}

.second .main2 .price {
    font-size: 28px;
    font-weight: 700;
    color: #219653;
    font-family: Mulish;
}

.second .main2 .text2 {
    font-size: 20px;
    font-weight: 500;
    font-family: Mulish;
    color: #757575;
}


.get_in_touch {
    border: 1px solid #DFDFDF;
    border-radius: 16px;
    padding: 2% 10%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10%;
    margin: 50px 0px;
}

.get_in_touch .left_content {
    display: flex;
    flex-direction: column;
}

.get_in_touch .left_content h5 {
    font-size: 32px;
    font-weight: 700;
    font-family: Mulish;
    color: #F4BA00;
    margin: 0;
    margin-bottom: 15px;
}

.get_in_touch .left_content .para {
    font-size: 16px;
    font-weight: 500;
    font-family: Mulish;
    color: #757575;
    margin: 0;
}

.get_in_touch .right_content .image2 {
    width: 300px;
    height: 300px;
}

.get_in_touch .left_content .links {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    font-size: 18px;
    font-weight: 500;
    font-family: Mulish;
    color: #888EAA;
    text-decoration: none;
    margin-top: 30px;
}

.left_content .links svg {
    font-size: 24px;
    color: #000;
}


/* responsive */
@media screen and (min-width:300px) and (max-width:600px) {

    .calculator .container {
        padding: 20px;
    }

    .bread_crumb {
        padding: 15px 0px;
        margin: 0px;
    }

    .calculator .main_content {
        padding: 0px;
    }

    .calculator .main_content .heading {
        font-size: 30px;
    }

    .image {
        width: 300px;
        height: 300px;
    }

    .pie_box h6 {
        font-size: 22px;
    }

    .pie_chart {
        flex-direction: column;
        padding: 15px;
        align-items: center;
    }

    .right_side {
        margin-top: 20px;
    }

    .right_side .top_sec {
        flex-direction: column;
        align-items: center;
    }

    .main_content .bottom_sec {
        margin: 15px;
    }

    .bottom_sec .first {
        padding: 10px;
        align-items: center;
        flex-direction: column;
    }

    .bottom_sec .first p {
        font-size: 18px;
    }

    .bottom_sec .first .first_span {
        font-size: 20px;
    }

    .bottom_sec .first .second_span {
        font-size: 20px;
    }

    .bottom_sec .second {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 10px;
        gap: 14px;
    }

    .bottom_sec .second .main {
        flex-direction: column;
        align-items: center;
        gap: 5px;
    }

    .bottom_sec .second .main2 {
        flex-direction: column;
        align-items: center;
        gap: 5px;
    }

    .second .main2 .text2 {
        text-align: center;
    }

    .second .main .head {
        font-size: 20px;
    }

    .second .main .text {
        font-size: 16px;
    }

    .second .main2 .price {
        font-size: 24px;
    }

    .second .main2 .text2 {
        font-size: 16px;
    }

    .get_in_touch {
        flex-direction: column;
        padding: 5% 2%;
    }

    .get_in_touch .left_content .links{
        margin-top: 25px;
    }
}
@import "//netdna.bootstrapcdn.com/font-awesome/3.0/css/font-awesome.css";

.heading {
  color: #000000;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
}

.main_heading {
    font-size: 40px;
}

.sub_heading1 {
  font-size: 30px;
}

.sub_heading2 {
    font-size: 22px;
}
.sub_heading3 {
    font-size: 20px;
}

.margin-top-20 {
    margin-top: 20px;
}

.mt-15 {
  margin-top: 15px;
}

.-mt-15 {
    margin-top:-15px
}



.section1 {
padding:40px 0px;
width:100%;
display:flex;
flex-direction: row;
align-items: center;
}

.aboutus-img { 
    object-fit:cover;
    width:42%;
    height:400px;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    flex-shrink: 0;

}

.our-vision-img {

    object-fit:cover;
    width:42%;
    height:300px;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    flex-shrink: 0;
}


.aboutUs { 
    width:58%;
    padding:0px 60px
}


.aboutUsDescription {
    color:#000000;
    font-size: 20px;
    text-align: justify;
    line-height: 25px;
    margin-bottom: 20px;
}


.desertSection {
    width:45%;
    padding:0px 60px
}

.ourVisionDescription {
    color:#fff;
    font-size: 20px;
    text-align: justify;
    line-height: 25px;
    margin-bottom: 20px;
    margin-top: 10px; 
    padding-right: 50px;
}



.section-container {
    width: 92%;
    margin :auto auto ;
    padding:70px 0px
}

.sub-content-card-container1 {
    display: flex;
    flex-direction: column; 
    width:40%;
    margin-right: 30px;
    /* width:100% */
}

.sub-content-card-container2 {
    display: flex;
    flex-direction: column; 
    width:60%
    /* width:100% */
}


.content-card {
    border:1px solid #D9D9D9;
    border-radius: 20px;
    padding:30px;
    flex:1
}

.content-card-container {
    display: flex;
    flex-direction: row;
    /* align-items: start; */
    width: 100%;
}

.contentDescription {
    color: white;
    font-size: 16px;
    text-align: justify; 
}

.teamSection {
    margin-top: 30px;
width: 100%;
display: flex;
flex-direction: row;
align-items: center;
justify-content: space-between;
flex-wrap: wrap;
gap: 30px;
}


.mentorSection {
    margin-top: 30px;
    width: 100%;
    display: flex;
    /* grid-template-columns: auto auto; */
    flex-direction: row;
    align-items: center;
    /* flex-wrap: wrap; */
    gap:10px
}

.our_mission {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: start;
    padding:23px 0px
    
    }

    .our-mission-text {
        color: white;
        font-size: 22px;
        text-align: justify; 
        padding:0px 32px;
        
    }

    .title-container {
            display: flex;
            flex-direction :row;
            align-items: center;
            justify-content: center;
            width: 100%;
            margin-top: 5px,
    }


    @media screen and (min-width:2560px){


.sub_heading1 {
    font-size: 48px;
  }
  
  .sub_heading2 {
      font-size: 40px;
  }
  .sub_heading3 {
      font-size: 32px;
  }
  

 .section1 {
  padding:70px 0px;
 }

           
.aboutus-img { 
    height:750px;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
}
.aboutUs { 
    padding:0px 110px
}

  
  .main_heading {
      font-size: 75px;
  }


.aboutUsDescription {
    color:#000000;
    font-size: 40px;
    text-align: justify;
    line-height: 50px;
    margin-bottom: 20px;
    margin-top:20px;
} 
 

.section-container {
    width: 92%;
    margin :auto auto ;
    padding:130px 0px
}


.teamSection{
    margin-top: 40px;
}

.our_mission {
    padding:30px 0px
    
}

.our-mission-text {
    font-size: 40px;
    padding:0px 70px;
    line-height: 50px;
    
}
 
.mentorSection {
    margin-top :50px;
    gap:40px
}
   


.mt-15 {
    margin-top: 25px;
  }
  
  .-mt-15 {
      margin-top:-25px
  }


  .our-vision-img {
    height:550px;
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
}

.desertSection {
    padding:0px 110px
}


.ourVisionDescription {
    font-size: 40px;
    line-height: 50px;
    margin-bottom: 20px;
    margin-top: 20px; 
    padding-right: 50px;
}


.contentDescription {
    font-size: 40px;
}

    }



    @media screen and (max-width:786px){
        
        .section1 {
            padding:20px 0px;
            flex-direction: column;
            align-items: start;
            }

       .aboutus-img {
        object-fit:cover;
        width:85%;
        height:320px;
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
        flex-shrink: 0;
       }

       .aboutUs { 
        width:90%;
        padding:0px 20px;
        margin-top:20px;
    } 
      
    .main_heading {
        font-size: 35px;
    }
    
    .sub_heading1 {
      font-size: 22px;
    }
    
    .sub_heading2 {
        font-size: 18px;
    }
    .sub_heading3 {
        font-size: 16px;
    
    }   

.aboutUsDescription {
    font-size: 16px;
    text-align: justify;
    line-height: 22px;
}

.section-container {
    padding:40px 0px
}

.content-card-container {
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.sub-content-card-container1 {
    margin-bottom: 20px;
}

.sub-content-card-container1,.sub-content-card-container2 {
    margin-right: 0px;
    width:100%
}

.teamSection {
margin-top: 15px;
width: 100%;
flex-direction: column;
}
.our_mission {
    width: 100%;
    display: flex;
    flex-direction:column;
    align-items: start;
    padding:0px 0px
    
    }
    .our-mission-text {
        font-size: 16px;
        padding:0px;
        margin-top: 15px;
    }

    .ourVisionDescription {
        font-size: 16px;
        /* line-height: 25px; */
        margin-bottom: 20px;
        margin-top: 10px; 
        padding-right: 50px;
    }
    
    .mentorSection {
        margin-top: 20px;
        flex-direction: column;
    } 

    .our-vision-img {
        object-fit: cover;
        width:90%;
        height:310px;
    }
    .desertSection {
        width:100%;
        padding:0px 20px;
        margin-top:20px;
    }

    .title-container {
        justify-content: start;
        margin-top: 5px,
}
    
    }
.main-heading {
    font-family: Mulish;
    font-size: 28px;
    font-weight: 800;
    line-height: 33.6px;
    letter-spacing: -0.005em;
    text-align: left;

}

.select-vehicle-type {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    /* 5 columns with equal width */
    grid-gap: 10px;
    /* Adjust gap between grid items */
    align-items: center;

}

.vehicle-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    white-space: nowrap;
    margin: 10px;
    border-radius: 8px;
    background-color: #fff;
    border: 1px solid #F5F5F5;
    padding: 20px 5px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    cursor: pointer;
    /* Add cursor pointer for clickable effect */
}

.vehicle_active {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    white-space: nowrap;
    margin: 10px;
    border-radius: 8px;
    background-color: #fff;
    border: 2px solid #F8B301;
    padding: 20px 5px;
    cursor: pointer;
}

.vehicle-box .selected {
    fill: yellow;
    /* Change fill color of SVG to yellow for selected vehicle */
}

.svg-text {
    font-family: Mulish;
    font-size: 14px;
    font-weight: 500;
    margin-top: 20px;
    text-align: center;
    color: #888888;
}

.text_active {
    font-family: Mulish;
    font-size: 14px;
    font-weight: 500;
    margin-top: 20px;
    text-align: center;
    color: #1E2130;
}


/* Media query for mobile devices */
@media screen and (max-width: 768px) {

    .select-vehicle-type {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-between;
    }

    .vehicle-box {
        width: 50%;
        /* Each item takes up half the width */
        box-sizing: border-box;
        padding: 10px;
    }

    /* Additional styling for responsiveness */
    .vehicle-box {
        width: 33.33%;
        /* Each item takes up one-third of the width on larger screens */
    }


    .main-heading {
        font-family: Mulish;
        font-size: 28px;
        font-weight: 800;
        line-height: 33.6px;
        letter-spacing: -0.005em;
        text-align: center;

    }
}
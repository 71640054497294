footer {
    background-color: #1e2130;
    padding: 2rem 0 2rem 0;
    margin-top: 60px;
}

.footer-container {
    width: 90%;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
}

.footer-column {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.footer-column h3 {
    font-size: 2.5rem;
    color: #ffffff;
}

.footer-column h6 {
    font-size: 2rem;
    margin: 1rem 0;
    color: #ffffff;
}

.footer-column ul {
    margin: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 30px;
    padding: 0;
}

.footer-column ul li {
    list-style: none;
    margin: 1rem 0;
}

.footer-links {
    text-decoration: none;
    font-size: 1.8rem;
    color: #888eaa;
}

.footer-btn {
    padding: 1rem;
    padding-left: 0px;
    padding-top: 0px;
    display: flex;
    align-items: center;
    margin-right: 1rem;
}

.footer-btn img:first-child {
    margin-right: 1rem;
}

.social-media-links {
    display: flex;
}

.social-media-links .footer-links {
    margin-right: 1rem;
}

.copyright {
    border-top: 2px solid #3f4356;
}

.copyright-inner {
    width: 90%;
    margin: 0 auto;
    display: grid;
    grid-template-columns: 10fr 2fr;
}

.copyright-inner a {
    font-size: 1.4rem;
    color: #888eaa;
    margin-top: 3rem;
    font-weight: 800;
}

.copyright-inner p {
    font-size: 1.4rem;
    color: #888eaa;
    margin-top: 3rem;
    font-weight: 600;
}

@media only screen and (min-width:300px) and (max-width:600px) {
    .footer {
        margin-top: 30px !important;
    }

    .footer-column {
        flex-direction: column;
    }

    .footer-column ul {
        flex-direction: column;
        gap: 0px;
    }
}

.info_card {
    width: 48%;
    margin-top: 15px;
}


.profile-container {
  border: 1px solid #1E2130 ;
  border-radius:20px;
  padding:25px;
}



.pic-name-section {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.profile-pic-container {
    width: 80px;
    height: 80px;
    border-radius: 100%;
    background-color: grey;
    overflow: hidden;
    margin-right: 20px;
}


.picture {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.profile-info-text {
    text-align: justify;
    font-size:18px;
    font-weight: 400px;
    color: #646464;

}

.info-text {
    text-align: justify;
    font-size:18px;
    font-weight: 400px;
    color: #646464;

}



@media screen and (min-width:2560px){

.info_card {
    margin-top:25px
}


.profile-container {
    border-radius:30px;
    padding:45px;
  }
  
  
  .profile-pic-container {
      width: 140px;
      height: 140px;
      margin-right: 30px;
  }
  
  .info-text , .profile-info-text {
      font-size:40px;
  }
}

@media screen and (max-width:786px){
    .profile-info-text , .info-text {
        font-size: 16px;
    }
    .info_card {
        width: 100%;
        margin-top: 5px;
    }

      .profile-pic-container {
          width: 70px;
          height: 70px;
          margin-right: 10px;
      }

     
}
.banner_section {
    background-image: url('../public/landing_img.webp');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 700px;
    padding: 20px 0px;
}

.banner_section .row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}

.banner_section .first_box {
    width: 45%;
    padding: 20px;
    position: relative;
    z-index: 1;
    background-color: #ffffff94;
}

.banner_section .second_box {
    width: 40%;
    position: relative;
    z-index: 1;
}

.banner_section .heading {
    font-size: 40px;
    font-weight: 800;
    color: #000;
    font-family: Mulish;
    margin-top: 0px;
}

.banner_section .heading span {
    font-size: 30px;
    font-weight: 700;
    color: #f6af33;
}


.second_box .form {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 1px solid #000000;
    border-radius: 8px;
    padding: 40px 20px 20px 20px;
    background-color: #fff;
}

.second_box .form_head {
    font-size: 32px;
    font-weight: 800;
    color: #000;
    font-family: Mulish;
    margin-bottom: 10px;
    margin-top: 0px;
}

.second_box .form_head span {
    font-size: 25px;
    font-weight: 700;
    color: #f6af33;
}

.second_box .para {
    font-size: 18px;
    font-weight: 400;
    color: #616161;
    font-family: Mulish;
    margin-bottom: 40px;
    margin-top: 0px;
    text-align: center;
    width: 70%;
}

.form .input_group {
    border: 1px solid #E0E0E0;
    border-radius: 8px;
    padding: 18px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    width: 93%;
}

.form .input_group svg {
    font-size: 22px;
    color: #888888;
}

.form .input {
    font-size: 15px;
    font-weight: 500;
    font-family: Mulish;
    color: #888888;
    border: none;
}

.form .input_row {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
    width: 100%;
}

.form .input_group1 {
    border: 1px solid #E0E0E0;
    border-radius: 8px;
    padding: 18px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    width: 42%;
    margin-bottom: 15px;
}

.form .input_group1 svg {
    font-size: 22px;
    color: #888888;
}

.form .sub_btn {
    border: 1px solid #000;
    border-radius: 8px;
    padding: 17px 24px;
    background-color: transparent;
    font-size: 18px;
    font-weight: 700;
    font-family: Mulish;
    width: 100%;
    margin-top: 30px;
    cursor: pointer;
}

.form .sub_btn span {
    font-size: 16px;
    color: #f6af33;
}

.select_box {
    width: 100%;
}

.explore-truck-item {
    width: 338px;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
}

/* about section */
.explore-truck-sections3 {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 50px;
}

.container1 {
    padding: 20px 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 6rem;
}

.aboutus-img {
    object-fit: cover;
    width: 42%;
    height: 400px;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    flex-shrink: 0;

}

.aboutUs {
    width: 58%;
    padding: 0px 60px
}

.aboutUsDescription {
    color: #a0a0a0;
    font-size: 18px;
    text-align: justify;
    line-height: 25px;
    margin-bottom: 20px;
}

@media screen and (min-width:300px) and (max-width:600px) {
    .banner_section {
        height: 800px;
    }

    .banner_section .row {
        flex-direction: column;
    }

    .banner_section .first_box {
        width: 90%;
        padding: 10px;
        margin-bottom: 10px;
    }

    .banner_section .heading {
        font-size: 30px;
    }

    .banner_section .heading span {
        font-size: 25px;
    }

    .banner_section .second_box {
        width: 100%;
    }

    .second_box .form {
        padding: 12px;
    }

    .second_box .form_head {
        font-size: 25px;
    }

    .second_box .form_head span {
        font-size: 20px;
    }

    .second_box .para {
        font-size: 14px;
        margin-bottom: 20px;
        width: 100%;
    }

    .form .input_row {
        flex-direction: column;
        gap: 0px;
    }

    .form .input {
        font-size: 14px;
    }

    .form .input_group {
        width: 90%;
        padding: 12px;
        margin-bottom: 10px;
    }

    .form .input_group1 {
        width: 90%;
        padding: 12px;
        margin-bottom: 10px;
    }

    .select_box {
        width: 100%;
    }

    .form .sub_btn {
        margin-top: 10px;
        padding: 12px 24px;
        font-size: 16px;
    }

    .explore-truck-sections {
        min-height: unset !important;
    }

    .container1 {
        flex-direction: column;
        padding: 20px 20px 0px 20px !important;
    }

    .aboutus-img {
        display: none;
    }

    .aboutUsDescription {
        font-size: 16px;
    }
}


@media (max-width: 767px) {

    .explore-truck-sections3 {
        padding: 0px !important;
    }

    .explore-truck-heading h3
    {
        font-size: 22px;
    }
}
.vehicle-req-container {
  padding: 10px 30px;
  width: 80%;
  margin: 10px auto;
  box-shadow: 0px 2px 32px 8px rgba(148, 148, 148, 0.08);
}




.vehicle-req-container>h2 {
  font-size: 36px;
  font-weight: 800;
  color: #1E2130;
}

.vehicle-req-container>p {
  font-size: 20px;
  font-weight: 400;
  margin: 10px 0px;
  color: #616161;
}

.vehicle-sell-form-container {
  border: 1px solid #FFE6A5;
  border-radius: 8px;
  margin: 30px 0px;
  padding: 30px;
}

.form-navigation-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-bottom: 2px solid #DEDEDE;
  padding: 20px 0px 30px 0px;
}

.form-navigation-container>.enabled-btn {
  padding: 10px 25px;
  background-color: #F8B301;
  border-radius: 7px;
  color: #1E2130;
  font-weight: 700;
  cursor: pointer;
}

.form-navigation-container>.disbaled-btn {
  padding: 10px 25px;
  background-color: #F7F7F7;
  border-radius: 7px;
  color: #1E2130;
  font-weight: 700;
  cursor: not-allowed;
}

/* Default styles for larger screens */
.veh-section-2 {
  background-color: #1e2130;
  border-radius: 7px;
  margin-top: 30px;
  padding: 42px 48px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.content-container>h3 {
  color: #fdebb0;
  font-size: 16px;
  font-weight: 700;
}

.content-container>p {
  color: #fff;
  font-size: 30px;
  font-weight: 700;
}

.veh-btn-container {
  height: fit-content;
  display: flex;
  flex-direction: row;
}

.veh-know-more {
  background-color: #f8b301;
  color: #fff;
  padding: 12px 12px;
  border: 1px solid #f8b301;
  font-size: 16px;
  border-radius: 4px;
  margin-right: 8px;
  cursor: pointer;
  height: 60px;
  font-weight: 700;
  width: max-content;
}

.veh-contact-us svg {
  color: #1e2130 !important;
}

.veh-contact-us {
  color: #1e2130;
  cursor: pointer;
  padding: 12px 12px;
  font-size: 16px;
  border: 1px solid #ffffff;
  border-radius: 4px;
  display: flex;
  align-items: center;
  font-weight: 700;
  height: 60px;
  white-space: nowrap;
}

/* Media query for smaller screens */
@media screen and (max-width: 768px) {
  .veh-section-2 {
    padding: 20px;
    /* Adjusted padding for smaller screens */
    flex-direction: column;
    /* Changed to column for mobile layout */
    align-items: center;
  }

  .content-container>h3 {
    font-size: 16px;
    /* Adjusted font size for smaller screens */
    text-align: center;
    /* Center-align text */
  }

  hr {
    width: 100%;
    border: 1px solid #E0E0E0;
    border-style: dashed;
  }

  .content-container>p {
    font-size: 20px;
    /* Adjusted font size for smaller screens */
    text-align: center;
    /* Center-align text */
  }

  .veh-btn-container {
    flex-direction: column;
    /* Changed to column for mobile layout */
    align-items: center;
    margin-top: 20px;
    /* Added margin for spacing */
  }

  .veh-know-more {
    margin-right: auto;
  }

  .veh-know-more,
  .veh-contact-us {
    padding: 10px 20px;
    /* Adjusted padding for smaller screens */
    margin-bottom: 8px;
    /* Added margin for spacing */
    width: 100%;
    max-width: 250px;
    /* Added max-width for button size control */
    text-align: center;
    /* Center-align text */
  }
}




.form-navigation-content {
  flex: 1;
  margin: 0px 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.form-navigation-content>.svg-text-box {
  display: flex;
  flex-direction: column;
  align-items: center;

}

.form-navigation-content>.svg-text-box>div {
  width: 32px;
  height: 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
}

.isActive>div {
  background-color: #FFEEC3;
}

.isDisable>div {
  background-color: #F4F4F4;
}

.isActive>h3 {
  color: #1E2130;
}

.isDisable>h3 {
  color: #616161;
}

.form-navigation-content>hr.new1 {
  border-top: 3px dashed #E0E0E0;
  border-bottom: none;
}

.sell-navigation-content-mobile {
  display: none;
}



@media only screen and (max-width: 768px) {
  .sell-navigation-content-mobile {
    display: grid;
    flex-direction: row;
    grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
    /* Equal width columns */
    align-items: center;
    justify-content: center;
  }



  .form-navigation-content {
    display: none;
  }

  .sell-navigation-content-mobile>.svg-text-box {
    margin: 10px;
    text-align: center;
    display: flex;
    /* Ensures icons and lines are in the same flex container */
    align-items: center;
    /* Vertically center the icons */
    position: relative;
    flex-direction: column;
  }

  .sell-navigation-content-mobile>.svg-text-box:not(:last-child)::after {
    content: '';
    width: 80%;
    height: 0;
    border-top: 1px dashed #E0E0E0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(25%);
  }

  .vehicle-sell-form-container {
    border: 1px solid #FFE6A5;
    border-radius: 8px;
    margin: 30px 0px;
  }




  .sell-navigation-content-mobile>.svg-text-box>div {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    background-color: #fff;
  }
}
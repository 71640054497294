.explore-truck-item1 {
    display: flex;
    width: 338px;
    padding: 18px;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
    flex-shrink: 0;
    border-radius: 18px;
    background: #1e2130;
    box-sizing: border-box;
}

.explore-truck-item1:hover {
    background-color: #1e2140;
    transform: scale(1.05);
    transition: all 0.3s ease-in-out;
    box-shadow: 0 0 5px rgba(255, 255, 255, 0.5);
}

.explore-truck-img-1 {
    position: relative;
    display: flex;
    height: 223px;
    align-items: flex-start;
    align-self: stretch;
    border-radius: 12px;
    overflow: hidden;
}

.mySwiper1 {
    width: 100%;
    height: 100%;
}

.explore-truck-img-1 img {
    height: 223px;
    width: 100%;
    object-fit: cover;
}

.span {
    position: absolute;
    /* left: 2vw; */
    /* width: 100%; */
    /* margin: 1rem auto; */
    width: auto !important;
    bottom: 0.5rem;
    margin: 0 0 12px 12px !important;
    display: flex;
    justify-content: flex-start;
    gap: 8px;
    z-index: 100;
}

.explore-truck-img-1 span {
    border-radius: 4px;
    background: rgba(255, 255, 255, 0.25);
    backdrop-filter: blur(10px);
    padding: 6px 12px;
    color: #ffffff;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.24px;
    text-transform: uppercase;
}

.explore-truck-img p {
    font-family: "Mulish";
    font-style: normal;
    font-weight: 500;
    font-size: 12.0325px;
    line-height: 15px;
    letter-spacing: 0.02em;
    text-transform: uppercase;
    color: #ffffff;
}

.explore-truck-description {
    padding: 12px;
    box-sizing: border-box;
    width: 100%;
    /* margin-bottom:1.5rem; */
    /* text-align: center; */
}

.explore-truck-description h3 {
    color: #ffffff;
    font-size: 24px;
    font-family: Mulish;
    font-weight: 800;
    letter-spacing: 0.48px;
    margin: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.explore-truck-img div {
    position: absolute;
    /* left: 2vw; */
    width: 100%;
    margin: 1rem auto;
    bottom: 0.5rem;
    margin-bottom: 1rem;
    display: flex;
    justify-content: flex-start;
    /* border: 1px solid white; */
}

.explore-truck-img span {
    margin: 0;
    font-size: 10px;
}

.explore-truck-details {
    font-family: "Mulish";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    /* identical to box height */

    letter-spacing: 0.02em;

    color: #b6bdde;
    color: #b6bdde;
    text-align: left;
    display: flex;
    justify-content: space-evenly;
    margin-top: 8px;
    margin-bottom: 18px;
    /* padding: 1rem; */
}

.explore-truck-details span {
    /* margin: 0 0.5rem; */
    letter-spacing: 0.1rem;
    font-size: 12px;
    font-family: Mulish;
    font-weight: 500;
}

.explore-truck-price {
    display: flex;
    align-items: flex-end;
    justify-content: start;
}

.explore-truck-price span:first-child {
    background: #f6af33;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 32px;
    margin: 0;
    margin-right: 1rem;
    font-weight: 800;
}

.explore-truck-price span:last-child {
    font-size: 1.5rem;
    color: #7d808e;
    text-decoration-line: line-through;
    margin-bottom: 0.6rem;
}

.inclusive {
    color: #7d808e;
    font-size: 150%;
    text-align: left;
    margin: 0;
    margin-right: 1rem;
}

.explore-truck-know-more {
    width: 100%;
    margin-top: 12px;
    box-sizing: border-box;
    background-color: #151720;
    border: 1px solid #151720;
    border-radius: 0.8rem;
    color: #ffffff;
    padding: 17px 27px;
    font-size: 16px;
    font-family: Mulish;
    font-weight: 700;
}

.explore-truck-know-more:hover {
    background-color: #151735;
    transform: scale(1.1);
    transition: all 0.1s ease-in 0.1s ease-out;
    /* box-shadow: 0 0 10px rgba(255, 255, 255, 0.5); */
}


.home_btn {
    display: inline-block;
    width: 100%;
    margin-top: 12px;
    box-sizing: border-box;
    background-color: #151720;
    border: 1px solid #151720;
    border-radius: 8px;
    color: #ffffff;
    padding: 13px 20px;
    font-size: 14px;
    font-family: Mulish;
    font-weight: 600;
    text-align: center;
    text-decoration: none;
}

.home_btn:hover {
    background-color: #151735;
    transform: scale(1.1);
    transition: all 0.1s ease-in 0.1s ease-out;
    /* box-shadow: 0 0 10px rgba(255, 255, 255, 0.5); */
}

@media (max-width: 1200px) {
    .explore-truck-img span {
        padding: 3px 6px;
    }
}

@media (max-width: 767px) {
    .explore-truck-item {
        width: 288.44px;
        padding: 15.361px;
        flex-direction: column;
        align-items: flex-start;
        gap: 0;
        flex-shrink: 0;
    }

    .explore-truck-item1 {
        width: 288.44px;
        padding: 15.361px;
        flex-direction: column;
        align-items: flex-start;
        gap: 0;
        flex-shrink: 0;
    }

    .explore-truck-img-1 img {
        height: 190.302px;
        align-items: flex-start;
        align-self: stretch;
    }

    .explore-truck-img-1 {
        height: 190.302px;
    }

    .explore-truck-description {
        padding-bottom: 0;
    }

    .explore-truck-description h3 {
        whitespace: nowrap;
        font-size: 20.481px;
        font-family: Mulish;
        font-style: normal;
        font-weight: 800;
        line-height: normal;
        letter-spacing: 0.41px;
    }

    .explore-truck-details span {
        font-size: 10.24px !important;
        font-family: Mulish;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.205px;
    }

    .explore-truck-price span:first-child {
        font-size: 27.308px;
        font-family: Mulish;
        font-style: normal;
        font-weight: 800;
        line-height: normal;
        letter-spacing: 0.546px;
    }

    .explore-truck-price span:last-child {
        font-size: 15.361px;
        font-family: Mulish;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .inclusive {
        font-size: 10.24px;
        font-family: Mulish;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        letter-spacing: 0.205px;
    }

    .explore-truck-know-more {
        /* position: absolute; */
        font-size: 1.3rem;
        /* width: 257px; */
        padding: 14.507px 20.481px 14.507px 25.601px;
        margin-top: 9px;
    }

    /*  .explore-truck-img div {
      
    }
    .explore-truck-img span {
      font-size: 12px;
    }

    .explore-truck-description {
      padding: 0.5rem;
      box-sizing: border-box;
      text-align: center; 
    }

    

    .explore-truck-details {
      font-size: 130%;
    }

    

    .explore-truck-price span:last-child {
      font-size: 2rem;
    }

    .inclusive {
      font-size: 140%;
    }

    .explore-truck-know-more {
      font-size: 1.3rem;
      width: 100%;
      padding: 1rem 0;
    } */
}
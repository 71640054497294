

.privacy-policy-text {
    font-size: 18px;
    text-align: justify;
    margin-top:20px;
}

.privacy-policy-heading {
    font-size:26px;
    font-weight:700;
}

.privacy-policy-sub-heading {
    font-weight: 600;
    font-size: 18px;
}
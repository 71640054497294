.breadcrumbs {
    display: flex;
    align-items: center;
    font-size: 14px; 
    padding:30px 0px ;
    flex-wrap: wrap;
    }
    
    .separator {
    margin: 0 10px;
    }
     

    .link-style {
        color: #818D9D;
        font-weight: 600;
        font-size:16px;
        text-decoration: none;


    }

    .active-breadcrumb { 
        padding: 6px 14px 6px 14px;
        border-radius: 4px;
        background-color: #FDEBB0;
;
    }

    @media screen and (max-width:425px) {
        .breadcrumbs {

        }
        
        .active-breadcrumb {
            font-size: 14px;
            margin-top: 15px;
        }

    }
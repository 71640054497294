.rc-details-main-box{
    border: 1px solid #FFE6A5;
    border-radius: 8px;
    margin: 30px 0px;
    padding: 25px;
}
.rc-page-status-box {
    display: flex;
    align-items: center;
    width: 53.5%;
    justify-content: space-between;
    padding: 15px;
}

.main-heading{
    font-family: Mulish;
font-size: 28px;
font-weight: 800;
line-height: 33.6px;
letter-spacing: -0.005em;
text-align: left;

}

.rc-btn-container{
    display: flex;
    flex-direction: row;
}
.rc-btn{
    cursor: pointer;
    border-radius: 7px;
    color: #1e2130;
    cursor: pointer;
    font-weight: 700;
    margin: 10px;
    padding: 12px 42px;
}
.rc-permit >select{
    background-color: white;
    font-size: 14px;
    margin-left: 10px;
    outline-style: none;
    padding: 2px;
    width: 100%;
}
.rc-btn > span{
    font-size: 16px;
    font-weight: 400;
    color: #1E2130;
    margin-left: 10px;
}

.rc-page-status-box >span{
    font-family: Mulish;
font-size: 14px;
font-weight: 600;
line-height: 16.8px;
letter-spacing: -0.005em;
text-align: left;

}

.rc-permit{
    align-items: center;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    display: flex;
    flex-direction: row;
    padding: 15px;
    margin: 5px;
    width: 50%;
}
  .selected-btn {
    background-color: #ffe6a5;
  }
  
  .fitness-column{
    display: flex;
    flex-direction: row;
    gap: 5px;
    padding-left: 5px;
  }

  .MuiInputLabel-root {
    font-family: Mulish  !important;
    font-size: 12px  !important;
    font-weight: 500  !important;
    line-height: 17.57px ;
    text-align: left;
  }
  .MuiInputLabel-shrink{
    font-size: 9px  !important;
  }

  @media screen and (max-width: 768px) {
    .fitness-column {
      display: flex;
      flex-direction: column;
    }

    .rc-page-status-box {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: space-between;
        padding: 15px;
        gap: 5px;
    }

    .rc-permit >select{
        
            background-color: white;
            font-size: 14px;
            margin-left: 10px;
            outline-style: none;
            padding: 2px;
            width: 100%;
            
        
    }
    .rc-btn {
        cursor: pointer;
        border-radius: 7px;
        color: #1e2130;
        cursor: pointer;
        font-weight: 700;
        margin: 10px;
        padding: 12px;
    }

    .rc-details-main-box {
      border: 1px solid #ffe6a5;
      border-radius: 8px;
      margin: 30px 0;
      padding: 15px;
  }

    .MuiStack-root {
        overflow: visible !important;
    }
    .rc-permit {
        align-items: center;
        border: 1px solid #e0e0e0;
        border-radius: 8px;
        display: flex;
        flex-direction: row;
        padding: 15px;
        margin: 5px;
        width: 88%;
    }
  }
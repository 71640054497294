@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Mulish:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&family=Passions+Conflict&display=swap");

html {
  font-size: 10px;
  font-family: "Mullish", sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

input,
textarea,
button,
select,
a {
  -webkit-tap-highlight-color: transparent;
}

::-webkit-scrollbar {
  width: 1.5rem;
  /* background: #fe8f2797; */
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background: #fe8f2797;
  border: 5px solid transparent;
  border-radius: 9px;
  height: 3rem;
  background-clip: content-box;
}

.width-100 {
  width: 100%;
}

input[type="color"],
input[type="date"],
input[type="datetime"],
input[type="datetime-local"],
input[type="email"],
input[type="month"],
input[type="number"],
input[type="password"],
input[type="search"],
input[type="tel"],
input[type="text"],
input[type="time"],
input[type="url"],
input[type="week"],
select:focus,
textarea {
  font-size: 16px;
}

@media (max-width: 568px) {
  .wa-widget-send-button {
    right: 20px !important;
  }

  .wa-chat-bubble {
    right: 13px !important;
  }

  .acelle-popup-loaded h2 {
    font-size: 1.5rem !important;
  }
}

/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */
/* --------------------------------------- new css add 10-05-2024 --------------------------------------------- */
/* --------------------------------------- new css add 10-05-2024 --------------------------------------------- */

.sliderimgvideo {
  position: relative !important;
}

.sliderimgvideo::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;

  background: linear-gradient(357deg, rgb(0 0 0 / 77%) 0%, transparent 83%);
  inset: 0;
  z-index: 3;
}

.sliderimgvideo .slider_main img {
  height: 700px !important;
}

.sliderimgvideo .swiper-button-next {
  background-color: #050505;
  padding: 2px;
  right: 0;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.sliderimgvideo .swiper-button-prev {
  background-color: #050505;
  padding: 2px;
  left: 0;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.sliderimgvideo .swiper-button-next:after,
.sliderimgvideo .swiper-button-prev:after {
  color: #ffff;
  font-size: 18px;
}

.btn_move_bottom {
  position: absolute;
  bottom: 110px;
  z-index: 66;
  width: 100%;
  left: 0;
  right: 0;
  margin: auto;
  text-align: center;
}

.btn_move_bottom .link_tab {
  display: inline-block;
  padding: 17px 34px;
  background-color: #f6af44;
  color: #fff;
  border-radius: 5px;
  font-size: 2.1rem;
  text-decoration: none;
  margin-right: 15px;
  border: 2px solid #f6af44;
  transition: all 0.5s ease-in-out;
}

.btn_move_bottom .link_tab:hover {
  transform: translateY(-5px);
  transition: all 0.5s ease-in-out;
}

.btn_move_bottom .link_tab.bg_black {
  background-color: #050505;
  border-color: #050505;
  transition: all 1s ease-in-out;
}

.btn_move_bottom .link_tab.bg_black:hover {
  transform: translateY(-5px);
  transition: all 0.5s ease-in-out;
}

.btn_black {
  padding: 17px 34px;
  background-color: #050505;
  border-color: #050505;
  color: #fff;
  border-radius: 5px;
  font-size: 2.1rem;
  text-decoration: none;
  margin-right: 15px;
  transition: all 0.5s ease-in-out;
}


input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input:focus-visible {
  outline: 0;
}


